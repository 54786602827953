import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
// LIB
import logoSVG from 'lib/media/logo.svg';

const HeaderWrapper = styled.div`
  padding: 8px 0;
  width: 100%;
  z-index: 99;
  height: 64px;
  position: sticky;
  top: 0;
  left: 0;

  @media (max-width: 480px) {
    padding: 10px 0;
  }

  &.fixed {
    background: ${(p) => p.theme.colors.white};
  }
`;

const Container = styled.div`
  width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1200px) {
    width: 950px;
  }

  @media (max-width: 992px) {
    width: 750px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Logo = styled.img`
  height: 32px;
`;

const Header = () => {
  const [isFixed, setFixed] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      setFixed(document.body.scrollTop > 0);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  });

  return (
    <HeaderWrapper className={isFixed ? 'fixed' : ''} ref={ref}>
      <Container>
        <Logo src={logoSVG} alt="logo" />
      </Container>
    </HeaderWrapper>
  );
};

export default Header;
