import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import FormItem from "components/common/FormItem";
import Button from "components/common/Button";
import Row from "components/common/Row";
import Col from "components/common/Col";
import TextInput from "components/inputs/Input";
import { RightOutlined } from "@ant-design/icons";

const FormContainer = styled.div`
  background: #fff;
  border-radius: 5px;
  width: 380px;
  max-width: 100%;
  margin: auto;
`;

const TermsText = styled.p`
  font-size: 14px !important;
  margin-top: 16px;
  color: ${(p) => p.theme.colors.neutral6};
  a {
    font-size: 14px !important;
  }
`;

const AlreadyHave = styled.h3`
  font-size: 14px;
  margin: 0px;
  margin-top: 8px;
  font-weight: 600;
  a {
    font-size: 14px !important;
    font-weight: 600;
  }
`;

const SectionBreak = styled.div`
  height: 2px;
  background: ${(p) => p.theme.colors.neutral9};
  border-radius: 20px;
`;

export default function BuyerSignupForm({ loading, onSubmit = () => {} }) {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    // clear errors whenever they resubmit
    setErrors({});
    // do some validation
    if (!values.firstName) {
      return setErrors({
        firstName: "Please provide a first name",
      });
    }
    if (!values.lastName) {
      return setErrors({
        lastName: "Please provide a last name",
      });
    }
    if (!values.companyName) {
      return setErrors({
        companyName: "Please provide a Business Name",
      });
    }
    if (!values.email) {
      return setErrors({
        email: "Please provide an email",
      });
    }
    if (!values.password) {
      return setErrors({
        password: "Please provide a password",
      });
    }

    // submit to a mutation here
    onSubmit(values);
  };

  const onChange = (newValue) => {
    setErrors({});
    setValues({ ...values, ...newValue });
  };

  return (
    <FormContainer id="sign-up-container">
      <form onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <FormItem label="First Name" required error={errors.firstName}>
              <TextInput
                data-testid="first-name-input"
                value={values.firstName}
                onChange={(e) => onChange({ firstName: e.target.value })}
              />
            </FormItem>
          </Col>
          <Col xs={24} sm={12}>
            <FormItem label="Last Name" required error={errors.lastName}>
              <TextInput
                data-testid="last-name-input"
                value={values.lastName}
                onChange={(e) => onChange({ lastName: e.target.value })}
              />
            </FormItem>
          </Col>
        </Row>
        <FormItem label="Business Name" required error={errors.companyName}>
          <TextInput
            data-testid="company-name-input"
            value={values.companyName}
            onChange={(e) => onChange({ companyName: e.target.value })}
          />
        </FormItem>
        <FormItem label="Email" required error={errors.email}>
          <TextInput
            placeholder="What email can we reach you at?"
            data-testid="email-input"
            value={values.email}
            type="email"
            inputMode="email"
            onChange={(e) => onChange({ email: e.target.value.toLowerCase() })}
          />
        </FormItem>
        <FormItem label="Password" required error={errors.password}>
          <TextInput
            value={values.password}
            data-testid="password-input"
            type="password"
            onChange={(e) => onChange({ password: e.target.value })}
          />
        </FormItem>
        <FormItem>
          <Button
            data-testid="sign-up-btn"
            type="submit"
            loading={loading}
            disabled={loading}
            onClick={handleSubmit}
          >
            Sign Up
          </Button>
        </FormItem>
      </form>{" "}
      <TermsText>
        By creating an account, you agree to Greenside’s{" "}
        <Link to="/terms" style={{ display: "inline-block", marginRight: 4 }}>
          Conditions of Use{" "}
        </Link>{" "}
        and <Link to="/privacy"> Privacy Notice</Link>
      </TermsText>
      <SectionBreak />
      <AlreadyHave>
        Already have an account?{" "}
        <Link to="/login">
          Sign-In <RightOutlined style={{ fontSize: 10, marginBottom: 2 }} />
        </Link>{" "}
      </AlreadyHave>
    </FormContainer>
  );
}
