import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// COMPONENTS
import Row from "components/common/Row";
import Col from "components/common/Col";
// APOLLO
import AuthHelpers from "lib/helpers/AuthHelpers";
// LIB
import logo from "lib/media/logo.svg";

const HeaderContainer = styled.div`
  background: #fff;
  font-family: ${(p) => p.theme.fontFamily};
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral9};
`;

const Username = styled.div`
  color: #000;
  font-family: ${(p) => p.theme.fontFamily};
  font-weight: 400;
  margin-left: auto;
`;

const SignoutBtn = styled.button`
  border: 0px;
  background: transparent;
  color: ${(p) => p.theme.colors.support1};
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  margin-left: 8px;
  font-family: ${(p) => p.theme.fontFamily};
  text-decoration: underline;
  cursor: pointer;
  font-weight: 400;
`;

const Header = ({ currentUser }) => {
  const onLogout = async () => {
    await AuthHelpers.signOut(currentUser.id);
  };

  return (
    <HeaderContainer>
      <Row
        style={{
          height: 56,
          margin: "auto",
          width: 1150,
          maxWidth: "90%",
          display: "flex",
          alignItems: "center",
        }}
      >
        {" "}
        <Col xs={6}>
          <Link to="/admin/dashboard">
            <img
              src={logo}
              alt="logo"
              style={{
                marginBottom: 8,
                position: "relative",
                left: -24,
                height: 32,
              }}
            />
          </Link>
        </Col>
        <Col xs={10}></Col>
        <Col xs={8} style={{ textAlign: "right" }}>
          <Username>
            logged-in as <strong>{currentUser && currentUser.email}</strong>{" "}
            <SignoutBtn onClick={onLogout}>sign-out</SignoutBtn>
          </Username>
        </Col>
      </Row>{" "}
    </HeaderContainer>
  );
};

export default Header;
