import styled from 'styled-components';

export const MenuItemBtn = styled.button`
  padding: 0px;
  margin: 0px;
  border: 0px;
  background: transparent;
  display: block;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 600;
  color: ${(p) => p.theme.colors.neutral3};
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.colors.neutral1};
  }
  &:focus {
    outline: 0;
  }
`;

export const SectionTitle = styled.h3`
  margin: 0px;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.neutral7};
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
`;

export const SectionBreak = styled.div`
  height: 2px;
  margin-bottom: 8px;
  margin-top: 8px;
  background: ${(p) => p.theme.colors.neutral10};
`;
