import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Button from 'components/common/Button';

const Overlay = styled.div`
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  width: 600px;
  min-height: 200px;
  background: #fff;
  border-radius: 5px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: ${(p) => p.theme.colors.neutral1};
`;

const Caption = styled.p`
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: ${(p) => p.theme.colors.neutral5};
`;

// const Circle = styled.div`
//   background: ${(p) => p.theme.colors.blue10};
//   border-radius: 50%;
//   height: 136px;
//   width: 136px;
//   margin: auto;
//   display: flex;
//   margin-bottom: 24px;
// `;

export default function WelcomeModal() {
  const [show, setShow] = useState(false);

  const onClose = () => {
    setShow(false);
    localStorage.setItem('supplierWelcomeModal', 'true');
  };

  useEffect(() => {
    const hasSeenModal = localStorage.getItem('supplierWelcomeModal');
    if (!hasSeenModal) {
      setShow(true);
    }
  }, []);

  if (!show) return null;

  return (
    <Overlay>
      <Content>
        <div>
          {/* <Circle /> */}
          <Title>Welcome to your Greenside Supplier Account</Title>
          <Caption>
            From here you'll be able to find new projects to quote, monitor your
            existing quotes and view your order history.
          </Caption>
          <Button
            data-testid="okay-supplier-modal-btn"
            onClick={onClose}
            style={{
              width: 175,
              margin: 'auto',
              display: 'block',
              marginTop: 24,
            }}
          >
            Okay
          </Button>
        </div>
      </Content>
    </Overlay>
  );
}
