import {useEffect, useState} from 'react';
import * as Sentry from '@sentry/browser';
import {Integrations} from '@sentry/tracing';

const STAGING_ENVS = ['test', 'local', 'staging', 'development'];

export default function useSentry() {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    // only run sentry if we're on staging or production... not locally
    if (!process.env.REACT_APP_SENTRY_DSN) return;
    if (loaded) return;
    if (!STAGING_ENVS.includes(process.env.NODE_ENV)) {
      setLoaded(true);
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        release: 'greenside@' + process.env.COMMIT_REF, // we need to figure out how to get more info from netlify to tag errors with certain commits
        tracesSampleRate: 1.0,
      });
    }
  }, [loaded]);
}
