/* This example requires Tailwind CSS v2.0+ */
import {
  GlobeAltIcon,
  LightningBoltIcon,
  ScaleIcon,
} from "@heroicons/react/outline";
import {
  IdentificationIcon,
  PresentationChartLineIcon,
  SupportIcon,
} from "@heroicons/react/solid";

const features = [
  {
    name: "Dedicated Account Manager",
    description:
      "Reach out to you rep by phone, text, or email which is most convenient",
    icon: SupportIcon,
  },
  {
    name: "Discounted Design & Engineering Services",
    description:
      "Access a suite of design & engineering services, sales proposals, permit packages, Helioscope and Aurora designs and more.",
    icon: PresentationChartLineIcon,
  },
  {
    name: "Premium Buyer Badge",
    description:
      "The premium buyer badge helps you unlock lower tiered pricing from our supplier network.",
    icon: IdentificationIcon,
  },
];

export default function Example() {
  return (
    <div className="py-20 bg-white pb-32" id="features">
      <div className="max-w-3xl mx-auto text-center mb-16 mt-8">
        <h2 className="text-3xl font-extrabold text-gray-900">
          Greenside Premium gives you all of your solar needs in one place.
        </h2>
        <p className="mt-0 text-lg text-gray-500" style={{ marginTop: 16 }}>
          Compare quotes from reputable distributors to find the highest-quality
          materials for the best price. Then complete your order and schedule
          delivery in a few clicks.
        </p>
      </div>
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">A better way to send money.</h2>
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
          {features.map((feature) => (
            <div key={feature.name}>
              <dt>
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-green-600 text-white">
                  <feature.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
