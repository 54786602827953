import React, {useState, lazy} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import ModalVideo from 'react-modal-video';
// COMPONENTS
import Row from 'components/common/Row';
import Col from 'components/common/Col';
import SEO from 'components/common/SEO';
// LIB
import HeroImg from 'lib/media/marketing/hero-bg.svg';
import playImg from 'lib/media/play-btn.svg';
import creditImg from 'lib/media/marketplace/credit-card-protection.svg';
import currencyImg from 'lib/media/marketplace/money-2.svg';
import moneyShieldImg from 'lib/media/marketplace/money-shield.svg';
import residentialImg from 'lib/media/landings/residential.svg';
import commercialImg from 'lib/media/landings/commercial.svg';
import utilityscaleImg from 'lib/media/landings/Img_utility_scale_project_2.svg';
import panelsImg from 'lib/media/categories/Img_panels.svg';
import balanceImg from 'lib/media/categories/Img_balance_of_materials.svg';
import batteryImg from 'lib/media/categories/Img_battery_accessories.svg';
import chargeImg from 'lib/media/categories/Img_charge_controllers.svg';
import electricPanelsImg from 'lib/media/categories/Img_electric_panels.svg';
import electricalWiringImg from 'lib/media/categories/Img_electrical_wiring.svg';
import energyStorageImg from 'lib/media/categories/Img_energy_storage.svg';
import generatorsImg from 'lib/media/categories/Img_generators.svg';
import inverterAccessoriesImg from 'lib/media/categories/Img_inverter_accessories.svg';
import inverterDisplayUnitsImg from 'lib/media/categories/Img_inverter_display_units.svg';
import rackingImg from 'lib/media/categories/Img_racking.svg';
import toolsSuppliesImg from 'lib/media/categories/Img_tools_supplies.svg';

import 'react-modal-video/css/modal-video.min.css';

/**
 * LAZY LOAD SOME COMPONENTS TO SPEED UP FIRST PAINT
 */
const MobileSignupCTA = lazy(() => import('./MobileSignupCTA'));
const FeaturedBrands = lazy(() => import('./FeaturedBrands'));
const FeaturedSection = lazy(() => import('./FeaturedSection'));
const BeatQuoteCTA = lazy(() => import('../../components/common/BeatQuoteCTA'));

const categories = [
  {
    title: 'Panels',
    img: panelsImg,
    slug: 'panels',
  },
  {
    title: 'Inverters',
    img: inverterDisplayUnitsImg,
    slug: 'inverters',
  },
  {
    title: 'Racking',
    img: rackingImg,
    slug: 'racking',
  },
  {
    title: 'Balance of Systems',
    img: balanceImg,
    slug: 'balance-of-systems',
  },
  {
    title: 'Battery Accessories',
    img: batteryImg,
    slug: 'battery-accessories',
  },
  {
    title: 'Charge Controllers',
    img: chargeImg,
    slug: 'charge-controllers',
  },
  {
    title: 'Energy Storage',
    img: energyStorageImg,
    slug: 'energy-storage',
  },
  {
    title: 'Inverter Accessories',
    img: inverterAccessoriesImg,
    slug: 'inverter-accessories',
  },
  {
    title: 'Generators',
    img: generatorsImg,
    slug: 'generators',
  },
  {
    title: 'Tools and Supplies',
    img: toolsSuppliesImg,
    slug: 'tools-and-supplies',
  },
  {
    title: 'Wire & Cable',
    img: electricalWiringImg,
    slug: 'wire-cable',
  },
  {
    title: 'Electric Panels',
    img: electricPanelsImg,
    slug: 'electric-panels',
  },
];

const projectSizes = [
  {
    title: 'Residential Projects',
    img: residentialImg,
    link: '/panels?ProductSearch[cells][]=60',
  },
  {
    title: 'Commercial Projects',
    img: commercialImg,
    link: '/panels?ProductSearch[cells][]=72',
  },
  {
    title: 'Utility Scale Projects',
    img: utilityscaleImg,
    link: '/panels?ProductSearch[cells][]=144',
  },
];

const Container = styled.div`
  width: 100%;
  margin: auto;
  padding: 0 15px;
  max-width: 100%;
  @media (min-width: 576px) {
    width: 540px;
  }

  @media (min-width: 768px) {
    width: 720px;
  }

  @media (min-width: 992px) {
    width: 960px;
  }

  @media (min-width: 1200px) {
    width: 1340px;
  }
`;

const Hero = styled.div`
  width: 100%;
`;

const HeroWrapper = styled.section`
  background: ${(p) => p.theme.colors.white} url(${HeroImg}) right bottom
    no-repeat;
  position: relative;
  @media (max-width: 768px) {
    background-position: 100px bottom;
    border-bottom: 1px solid ${(p) => p.theme.colors.neutral7};
  }

  @media (max-width: 480px) {
    background-position: 0 bottom;
    border-bottom: 1px solid ${(p) => p.theme.colors.neutral7};
  }
`;

const HeroSection = styled.div`
  display: flex;
  justify-content: space-between;
  /* padding: 0 5%; */
`;

const HeroTitleSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 280px;
  @media (max-width: 768px) {
    height: 236px;
    padding-top: 20px;
    justify-content: flex-start;
  }
`;

const HeroSubTitle = styled.p`
  font-size: 17px;
  font-weight: 600;
  color: ${(p) => p.theme.colors.neutral1};
  margin: 0;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
    padding-top: 30px;
  }

  @media only screen and (max-width: 480px) {
    padding-top: 0;
  }
`;

const HeroTitle = styled.h1`
  font-size: 41px;
  line-height: 1.4;
  color: ${(p) => p.theme.colors.neutral1};
  padding-top: 20px;

  @media only screen and (max-width: 768px) {
    font-size: 24px;
    padding-top: 10px;
  }
`;

const HeroSubSpan = styled.span`
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  display: block;
  font-weight: 600;
`;

const VideoPlayButton = styled.button`
  width: 72px;
  height: 72px;
  background: 0 0;
  border: 0;
  position: absolute;
  right: 40%;
  bottom: 35%;
  text-decoration: none;
  outline: 0 !important;
  margin: auto 0;
  cursor: pointer;

  @media (min-width: 1450px) {
    right: 32%;
  }
  /* margin-right: 325px;

  @media (max-width: 992px) {
    margin-right: 130px;
  }

  @media (max-width: 576px) {
    margin-right: 80px;
  }

  @media (max-width: 480px) {
    margin-right: 40px;
  } */
`;

const VideoPlayImg = styled.img`
  border: 0;
  vertical-align: middle;
  width: 100%;
  height: 100%;
`;

const Video = styled(ModalVideo)`
  .modal-video-close-btn {
    display: none;
  }
`;

const AdvantageContainer = styled.div`
  background: ${(p) => p.theme.colors.white2};
  padding: 40px 5% 28px;
`;

const Figure = styled.figure`
  display: flex;
  align-items: flex-start;
`;

const FigureImg = styled.img`
  width: 120px;
`;

const FigureCaption = styled.figcaption`
  font-size: 15px;
  line-height: 1.4;
  font-weight: 600;
  color: ${(p) => p.theme.colors.neutral6};
  margin-left: 20px;
`;

const FigureTitle = styled.span`
  display: block;
  font-size: 17px;
  line-height: 1.4;
  color: ${(p) => p.theme.colors.blue12};
  padding-bottom: 3px;
`;

const Section = styled.section`
  padding: 64px 15px;
  @media (max-width: 480px) {
    padding: 32px 15px;
  }
`;

const SectionTitle = styled.h2`
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.blue13};
  margin-bottom: 40px;

  @media only screen and (max-width: 480px) {
    margin-bottom: 24px;
    font-size: 18px;
  }
`;

const MainCategory = styled(Row)``;

const CategoryItem = styled(Link)`
  text-align: center;
  max-width: 150px;
  text-decoration: none !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  color: ${(p) => p.theme.colors.neutral1};
`;

const CategoryItemImg = styled.img`
  width: 140px;
  height: 140px;
  display: inline-block;
  border-radius: 50%;
  margin-top: 24px;
  background-size: cover;
`;

const CategoryItemTitle = styled.span`
  font-weight: 700;
  font-size: 18px;
  overflow-wrap: anywhere;
  color: ${(p) => p.theme.colors.neutral1};
`;

const ProjectSizeItem = styled.a`
  text-align: center;
  text-decoration: none !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 255px;
  padding: 16px;
  border-radius: 5px;
  border: 2px solid transparent;
  &:hover {
    border: 2px solid ${(p) => p.theme.colors.neutral10};
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.06);
  }

  @media (max-width: 992px) {
    margin-bottom: 64px;
  }
`;

const ProjectSizeItemImg = styled.img`
  width: 100%;
  height: 164px;
  display: inline-block;
  margin: auto;
  background-size: cover;
`;

const ProjectSizeItemTitle = styled.span`
  font-weight: 700;
  font-size: 18px;
  color: ${(p) => p.theme.colors.neutral1};
`;

export default function EcomHome({currentUser, currentUserLoading, history}) {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <SEO />
      <Hero>
        <HeroWrapper>
          <HeroSection
            style={{
              width: 1200,
              maxWidth: '95%',
              margin: 'auto',
            }}
          >
            <HeroTitleSection>
              <HeroSubTitle>One-stop-shop for energy solutions</HeroSubTitle>
              <HeroTitle>
                All your solar needs<HeroSubSpan>in one place.</HeroSubSpan>
              </HeroTitle>
            </HeroTitleSection>
          </HeroSection>
          <VideoPlayButton onClick={() => setOpen(true)}>
            <VideoPlayImg src={playImg} alt="" />
          </VideoPlayButton>
        </HeroWrapper>
        <AdvantageContainer>
          <Row style={{width: 1200, maxWidth: '100%', margin: 'auto'}}>
            <Col span={24} md={8}>
              <Figure>
                <FigureImg src={creditImg} alt="" />
                <FigureCaption>
                  <FigureTitle>Trusted vendors</FigureTitle>Greenside takes the
                  guesswork out of finding high quality components.
                </FigureCaption>
              </Figure>
            </Col>
            <Col span={24} md={8}>
              <Figure>
                <FigureImg src={moneyShieldImg} alt="" />
                <FigureCaption>
                  <FigureTitle>Trade assurance</FigureTitle>Protect against
                  damage and late deliveries with Greenside Assurance.
                </FigureCaption>
              </Figure>
            </Col>
            <Col span={24} md={8}>
              <Figure>
                <FigureImg src={currencyImg} alt="" />
                <FigureCaption>
                  <FigureTitle>Simple payments</FigureTitle>Forget tracking
                  bills, make one simple payment through Greenside.
                </FigureCaption>
              </Figure>
            </Col>
          </Row>
        </AdvantageContainer>
      </Hero>
      <Video
        channel="vimeo"
        isOpen={isOpen}
        videoId="396078031"
        onClose={() => setOpen(false)}
      />
      <Container>
        <Section>
          <SectionTitle style={{marginBottom: 16}}>
            FEATURED CATEGORIES
          </SectionTitle>
          <MainCategory>
            {categories.map((category, index) => (
              <Col key={index} span={12} sm={8} md={6} lg={4}>
                <CategoryItem to={category.slug}>
                  <CategoryItemImg src={category.img} alt="" />
                  <CategoryItemTitle>{category.title}</CategoryItemTitle>
                </CategoryItem>
              </Col>
            ))}
          </MainCategory>
        </Section>
        <FeaturedBrands />
        <Section>
          <SectionTitle>SEARCH BY PROJECT SIZE</SectionTitle>
          <MainCategory>
            {projectSizes.map((size, index) => (
              <Col key={index} span={24} lg={8}>
                <ProjectSizeItem href={size.link}>
                  <ProjectSizeItemImg src={size.img} alt="" />
                  <ProjectSizeItemTitle>{size.title}</ProjectSizeItemTitle>
                </ProjectSizeItem>
              </Col>
            ))}
          </MainCategory>
        </Section>
        <FeaturedSection title="FEATURED PANELS" categorySlug="panels" />
        <FeaturedSection title="FEATURED INVERTERS" categorySlug="inverters" />
        <FeaturedSection
          title="FEATURED BALANCE OF SYSTEMS"
          categorySlug="balance-of-systems"
        />
        <FeaturedSection
          title="FEATURED ENERGY STORAGE"
          categorySlug="energy-storage"
        />
      </Container>
      <BeatQuoteCTA currentUser={currentUser} />
      {!currentUserLoading && (
        <MobileSignupCTA
          currentUser={currentUser}
          currentUserLoading={currentUserLoading}
        />
      )}
    </>
  );
}
