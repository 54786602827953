import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {
  FacebookFilled,
  TwitterSquareFilled,
  LinkedinFilled,
} from '@ant-design/icons';
import Row from 'components/common/Row';
import Col from 'components/common/Col';
// LIB
import logoSVG from 'lib/media/Greenside_logo.svg';

const FooterWrapper = styled.div`
  background: ${(p) => p.theme.colors.blue11};
  padding-top: 55px;
`;

const Container = styled.div`
  width: 1170px;
  margin: 0 auto;
  padding: 0 15px;

  @media (max-width: 1200px) {
    width: 950px;
  }

  @media (max-width: 992px) {
    width: 750px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FooterLogo = styled.div``;

const Logo = styled.img`
  height: 40px;
`;

const FooterSubTitle = styled.span`
  display: block;
  padding-top: 10px;
  font-size: 18px;
  line-height: 1.4;
  color: ${(p) => p.theme.colors.blue10};
`;

const FooterMenuTitle = styled.p`
  font-size: 14px;
  line-height: 1.4;
  color: ${(p) => p.theme.colors.primary10};
  font-weight: 600;
  padding-bottom: 15px;
`;

const FooterMenuWrapper = styled.nav``;
const FooterMenu = styled.ul`
  list-style: none;
  padding: 0;
`;

const FooterMenuItem = styled.li``;

const NavLink = styled(Link)`
  display: block;
  padding-bottom: 10px;
  font-weight: 600;
  line-height: 1.4;
  font-size: 14px;
  color: ${(p) => p.theme.colors.neutral7};
  transition: all ease-in 0.2s;

  &:hover {
    color: ${(p) => p.theme.colors.primary10};
    text-decoration: underline;
  }
`;

const FooterMain = styled(Col)`
  margin-top: 1rem;

  @media (min-width: 576px) {
    margin-top: 0 !important;
  }
`;

const FooterBottom = styled(Row)`
  border-top: 2px solid ${(p) => p.theme.colors.blue10};
  margin-top: 64px;
  padding: 15px 0 25px;
  align-items: center;

  @media (max-width: 480px) {
    margin-top: 40px;
  }
`;

const FooterSocial = styled(Row)``;

const SocialLink = styled.a`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 15px;
  line-height: 20px;
  text-align: center;
  color: ${(p) => p.theme.colors.neutral8};
`;

const FooterTextWrapper = styled.div``;

const FooterText = styled.p`
  font-size: 12px;
  line-height: 1.4;
  color: ${(p) => p.theme.colors.primary10};
  font-weight: 600;
  margin: 0;
`;

const Footer = ({currentUser}) => {
  return (
    <FooterWrapper>
      <Container>
        <Row>
          <Col sm={{span: 6}}>
            <FooterLogo>
              <Logo src={logoSVG} alt="Greenside Logo" />
              <FooterSubTitle>
                Products for the world’s most forward-thinking companies
              </FooterSubTitle>
            </FooterLogo>
          </Col>
          <FooterMain sm={{span: 16, offset: 2}}>
            <Row>
              <Col span={12} sm={{span: 6}}>
                <FooterMenuTitle>Solutions</FooterMenuTitle>
                <FooterMenuWrapper>
                  <FooterMenu>
                    {/* <FooterMenuItem>
                      <NavLink to="/propertyowner">Property Owners</NavLink>
                    </FooterMenuItem> */}
                    <FooterMenuItem>
                      <NavLink to="/procurement">Installers</NavLink>
                    </FooterMenuItem>
                    <FooterMenuItem>
                      <NavLink to="/suppliers">Suppliers</NavLink>
                    </FooterMenuItem>
                    <FooterMenuItem>
                      <NavLink to="/suppliers">Manufacturers</NavLink>
                    </FooterMenuItem>
                    {/* <FooterMenuItem>
                      <NavLink to="/service">Service Providers</NavLink>
                    </FooterMenuItem> */}
                  </FooterMenu>
                </FooterMenuWrapper>
              </Col>
              <Col span={12} sm={{span: 6}}>
                <FooterMenuTitle>Work with Us</FooterMenuTitle>
                <FooterMenuWrapper>
                  <FooterMenu>
                    <FooterMenuItem>
                      <NavLink to="/site/distribution">
                        Sell on Greenside
                      </NavLink>
                    </FooterMenuItem>
                    <FooterMenuItem>
                      <NavLink to="/contact">Contact Us</NavLink>
                    </FooterMenuItem>
                    {/* <FooterMenuItem>
                      <NavLink to="/site/trade-assurance">
                        Trade Assurance
                      </NavLink>
                    </FooterMenuItem> */}
                  </FooterMenu>
                </FooterMenuWrapper>
              </Col>
              <Col span={12} sm={{span: 6}}>
                <FooterMenuTitle>Company</FooterMenuTitle>
                <FooterMenuWrapper>
                  <FooterMenu>
                    <FooterMenuItem>
                      <NavLink to="/site/about-us">About Us</NavLink>
                    </FooterMenuItem>
                    <FooterMenuItem>
                      <NavLink to="/careers">Careers</NavLink>
                    </FooterMenuItem>
                    <FooterMenuItem>
                      <NavLink to="/legal#terms">Terms of Service</NavLink>
                    </FooterMenuItem>
                    <FooterMenuItem>
                      <NavLink to="/legal#privacy">Privacy Policy</NavLink>
                    </FooterMenuItem>
                  </FooterMenu>
                </FooterMenuWrapper>
              </Col>
              <Col span={12} sm={{span: 6}}>
                <FooterMenuTitle>Get Help</FooterMenuTitle>
                <FooterMenuWrapper>
                  <FooterMenu>
                    {!currentUser && (
                      <FooterMenuItem>
                        <NavLink to="/login">Your Account</NavLink>
                      </FooterMenuItem>
                    )}
                    {currentUser && currentUser.id && (
                      <FooterMenuItem>
                        <NavLink to="/distributor/view/orders">
                          Your Orders
                        </NavLink>
                      </FooterMenuItem>
                    )}
                    <FooterMenuItem>
                      <NavLink to="/support">Contact Us</NavLink>
                    </FooterMenuItem>
                    <FooterMenuItem>
                      <NavLink to="/legal#shipping">
                        Shipping Rates & Policies
                      </NavLink>
                    </FooterMenuItem>
                    <FooterMenuItem>
                      <NavLink to="/legal#returns">
                        Returns & Replacements
                      </NavLink>
                    </FooterMenuItem>
                  </FooterMenu>
                </FooterMenuWrapper>
              </Col>
            </Row>
          </FooterMain>
        </Row>
        <FooterBottom justify="space-between">
          <FooterSocial>
            <SocialLink href="https://facebook.com/greensideenergy">
              <FacebookFilled style={{fontSize: 20}} />
            </SocialLink>
            <SocialLink href="https://twitter.com/greensideenergy">
              <TwitterSquareFilled style={{fontSize: 20}} />
            </SocialLink>
            <SocialLink href="https://linkedin.com/greensideenergy">
              <LinkedinFilled style={{fontSize: 20}} />
            </SocialLink>
          </FooterSocial>
          <FooterTextWrapper>
            <FooterText>
              © {new Date().getFullYear()} Greenside Energy, LLC
            </FooterText>
          </FooterTextWrapper>
        </FooterBottom>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
