import React, {useState} from 'react';
import styled from 'styled-components';
import iconSVG from './icon.svg';
import {LoadingOutlined} from '@ant-design/icons';
// APOLLO
import {useMutation} from '@apollo/client';
import SEND_RESET_PASSWORD_EMAIL from 'ApolloClient/Mutations/sendResetPasswordEmail';

const Container = styled.div`
  position: fixed;
  bottom: 24px;
  right: 24px;
  border-radius: 5px;
  background: ${(p) => p.theme.colors.yellow9};
  padding: 16px;
  width: 600px;
  max-width: 100%;
  display: flex;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 18px;
  color: ${(p) => p.theme.colors.yellow1};
  margin: 0px;
`;

const Caption = styled.p`
  font-weight: 400;
  color: ${(p) => p.theme.colors.yellow2};
  margin: 0px;
  margin-bottom: 8px;
`;

const LinkButton = styled.button`
  margin: 0px;
  padding: 0px;
  font-weight: 600;
  text-decoration: underline;
  color: ${(p) => p.theme.colors.yellow2};
  background: transparent;
  border: 0px;
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.colors.yellow1};
  }
`;

const Image = styled.img`
  position: relative;
  bottom: -3px;
`;

export default function SetPasswordReminder({currentUser}) {
  const [success, setSuccess] = useState(false);
  const [sendResetPasswordEmail, {loading}] = useMutation(
    SEND_RESET_PASSWORD_EMAIL
  );

  const handleSendReset = async () => {
    try {
      await sendResetPasswordEmail({
        variables: {
          email: currentUser.email,
        },
      });
      setSuccess(true);
    } catch (err) {
      console.log(err);
    }
  };

  if (success) {
    return (
      <Container>
        <div>
          <Title>Password reset email sent</Title>
          <Caption>
            We've sent an email to <strong>{currentUser.email}</strong> with a
            link for setting you password.{' '}
            {/*It usually arrives immidiately.
            However, if the email has not arrived within 10 minutes, contact
            support and we'll be happy to help. */}
          </Caption>
        </div>
      </Container>
    );
  }
  return (
    <Container>
      <div style={{marginRight: 16}}>
        <Image src={iconSVG} alt="Set a password" />
      </div>
      <div>
        {loading && <LoadingOutlined />}
        {!loading && (
          <>
            <Title>Set a password</Title>
            <Caption>
              You have not set a password for this Greenside account. Setting a
              password will allow you to get into your Greenside Supplier
              account at a later date to monitor your quotes and find new
              projects to quote.
            </Caption>
            <LinkButton onClick={handleSendReset}>
              Click here to set a password
            </LinkButton>
          </>
        )}
      </div>
    </Container>
  );
}
