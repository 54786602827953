import {gql} from '@apollo/client';
import buyerFragment from 'ApolloClient/Fragments/buyerFragment';
import supplierFragment from 'ApolloClient/Fragments/supplierFragment';

export default gql`
  query currentUser {
    currentUser {
      id
      roles
      email
      firstName
      lastName
      verified
      noPasswordSet
      phoneNumber
      isVerifyingPhone
      notificationSettings {
        notificationType
        sms
        email
        push
      }
      supplier {
        ...supplierFragment
      }
      buyer {
        ...buyerFragment
        resaleCertVerified
        isReferralPartner
        resaleCertAttachments {
          id
          url
          filename
        }
        paymentMethods {
          id
          cardType
          expirationMonth
          expirationYear
          lastFour
          type
          service
          default
        }
        openCart {
          id
          lineItems {
            id
          }
        }
      }
    }
  }
  ${buyerFragment}
  ${supplierFragment}
`;
