import {gql} from '@apollo/client';

export default gql`
  mutation signupBuyer(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $companyName: String!
    $referralId: ID
  ) {
    signupBuyer(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      companyName: $companyName
      referralId: $referralId
    ) {
      userId
      accessToken
      refreshToken
    }
  }
`;
