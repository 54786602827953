import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const AppNavContainer = styled.div`
  font-family: ${(p) => p.theme.fontFamily};
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral8};
  background: #fff;
`;

const NavItemContainer = styled.div`
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 16px !important;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  a {
    color: ${(p) =>
      p.active ? p.theme.colors.primary3 : p.theme.colors.neutral6} !important;
  }
  a:hover {
    color: ${(p) => p.theme.colors.primary3} !important;
  }
`;

const ActiveBar = styled.div`
  height: 6px;
  width: 100%;
  position: relative;
  bottom: -11px;
  background: ${(p) => (p.active ? p.theme.colors.primary3 : 'transparent')};
`;

const NavItem = ({children, active, to}) => (
  <div>
    <NavItemContainer active={active}>
      <Link to={to} style={{fontSize: 18}}>
        {children}
      </Link>
    </NavItemContainer>
    <ActiveBar active={active} />
  </div>
);

const MobileCol = styled.div`
  display: inline-flex !important;
  margin-left: 32px;
`;

const RightContainer = styled.div`
  flex: 2;
  justify-content: flex-end;
  display: flex;
  margin-top: 19px;
  @media only screen and (max-width: 414px) {
    display: none !important;
  }
`;

const AppNav = ({pathname}) => {
  return (
    <React.Fragment>
      <AppNavContainer>
        <div
          style={{
            height: 72,
            margin: 'auto',
            width: 1150,
            maxWidth: '90%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <RightContainer>
            <MobileCol>
              <NavItem
                to={`/admin/dashboard`}
                active={pathname === '/admin/dashboard'}
              >
                DASHBOARD
              </NavItem>
            </MobileCol>
            <MobileCol>
              <NavItem
                to={`/admin/users`}
                active={
                  pathname &&
                  (pathname.includes('/users') || pathname === '/admin')
                }
              >
                USERS
              </NavItem>
            </MobileCol>
            <MobileCol>
              <NavItem
                to={`/admin/suppliers`}
                active={pathname && pathname.includes('/suppliers')}
              >
                SUPPLIERS
              </NavItem>
            </MobileCol>
            <MobileCol>
              <NavItem
                to={`/admin/buyers`}
                active={pathname && pathname.includes('/buyers')}
              >
                BUYERS
              </NavItem>
            </MobileCol>
            <MobileCol>
              <NavItem
                to={`/admin/projects`}
                active={pathname && pathname.includes('/projects')}
              >
                PROJECTS
              </NavItem>
            </MobileCol>
            <MobileCol>
              <NavItem
                to={`/admin/products`}
                active={pathname && pathname.includes('/products')}
              >
                PRODUCTS
              </NavItem>
            </MobileCol>
            <MobileCol>
              <NavItem
                to={`/admin/orders`}
                active={pathname && pathname.includes('/orders')}
              >
                ORDERS
              </NavItem>
            </MobileCol>
            <MobileCol>
              <NavItem
                to={`/admin/support`}
                active={pathname && pathname.includes('/support')}
              >
                SUPPORT
              </NavItem>
            </MobileCol>
            <MobileCol>
              <NavItem
                to={`/admin/settings`}
                active={pathname && pathname.includes('/settings')}
              >
                SETTINGS
              </NavItem>
            </MobileCol>
          </RightContainer>
        </div>
        {/* */}
      </AppNavContainer>
    </React.Fragment>
  );
};

export default AppNav;
