import React from "react";

// COMPONENTS
import { Helmet } from "react-helmet-async";
//
import twitterPng from "./Twitter.png";
// import facebookPng from './Facebook.png';
import googlePng from "./Google.png";

const SEO = ({
  image = googlePng,
  description = "Solar Marketplace for Solar Panels, Inverters and Batteries. Post your project and get multiple solar supplier quotes",
  title = "Greenside Energy | Solar Marketplace for Solar Panels, Inverters and Batteries",
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* OPEN GRAPH */}
      <meta property="og:image" content={image || googlePng} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:site_name" content="Greenside Energy" />
      {/* TWITTER */}
      <meta name="twitter:creator" content="@greensideenergy" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image || twitterPng} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@greensideenergy" />
    </Helmet>
  );
};

export default SEO;
