import React from "react";
import styled from "styled-components";
import collectResponsesSvg from "./collect_responses.svg";
import easyPaymentOptionsSvg from "./easy_payment_options.svg";
import pageMaterialListSvg from "./page_material_list.svg";

const traditionalWaySteps = [
  {
    svg: collectResponsesSvg,
    heading: "Disorganized supply management with no visibility.",
    text: "Most contractors have a hard time accurately tracking product availability, orders and spend across multiple distributors and suppliers.",
  },
  {
    svg: easyPaymentOptionsSvg,
    heading: "Getting fair and consistent pricing is a challenge.",
    text: "Installers pay different prices for the same products because pricing is not transparent and changes frequently. Most contractors pay too much.",
  },
  {
    svg: pageMaterialListSvg,
    heading: "Manual ordering leads to expensive mistakes.",
    text: "Manually creating material lists and calling or texting to place orders leads to wrong product orders, incorrect quantities and overspending.",
  },
];

const TraditionalWayWrapper = styled.section`
  background-color: ${(p) => p.theme.colors.primary10};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1200px) {
    height: 548px;
  }

  @media (max-width: 1200px) {
    padding: 60px 0px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    width: 90%;
  }
`;

const TraditionalWayTitle = styled.h1`
  font-size: 28px;
  color: ${(p) => p.theme.colors.primary2};
  font-weight: 600;
  line-height: 1.35;
  max-width: 681px;
  text-align: center;
`;

const TraditionalWaySteps = styled.div`
  display: flex;
  margin-top: 30px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const TraditionalWayStep = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  justify-content: center;
  align-items: center;

  @media (min-width: 1200px) {
    &:not(:last-child) {
      border-right: 3px solid white;
      padding-right: 30px;
    }
  }

  &:last-child {
    margin-right: 30px;
  }
`;

const StepImg = styled.img`
  margin-bottom: 38px;
  height: 140px;
`;

const StepContent = styled.div`
  display: flex;
`;

const StepNumber = styled.div`
  background-color: ${(p) => p.theme.colors.primary3};
  height: 40px;
  width: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

const StepTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepHeading = styled.h1`
  font-size: 18px;
  color: ${(p) => p.theme.colors.neutral3};
  font-weight: 700;
  line-height: 1.5;
`;

const StepText = styled.p`
  color: ${(p) => p.theme.colors.neutral3};
  line-height: 1.6;
`;

const TraditionalWay = () => {
  return (
    <TraditionalWayWrapper id="howitworks">
      <Container>
        <TraditionalWayTitle>
          The traditional way of purchasing solar materials is holding your
          company back.
        </TraditionalWayTitle>
        <TraditionalWaySteps>
          {traditionalWaySteps.map((step, idx) => (
            <TraditionalWayStep key={idx}>
              <StepImg src={step.svg} alt={step.heading} />
              <StepContent>
                <StepNumber>{idx + 1}</StepNumber>
                <StepTextContainer>
                  <StepHeading>{step.heading}</StepHeading>
                  <StepText>{step.text}</StepText>
                </StepTextContainer>
              </StepContent>
            </TraditionalWayStep>
          ))}
        </TraditionalWaySteps>
      </Container>
    </TraditionalWayWrapper>
  );
};

export default TraditionalWay;
