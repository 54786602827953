import React from 'react';
import styled from 'styled-components';
import EcomHeader from 'components/common/EcomHeader';
import Footer from 'components/common/Footer';

/**
 * LAZY LOAD SOME STUFF
 */
// STYLE-COMPONENTS
// ===================================
const Layout = styled.div`
  height: 100%;
  width: 100%;
  max-width: 100%;
  background-color: ${(p) => (p.backgroundColor ? p.backgroundColor : null)};
`;

// EXPORT
// ===================================
export default function EcomLayout({children, currentUser, backgroundColor}) {
  return (
    <Layout id="ecom-layout" backgroundColor={backgroundColor}>
      <EcomHeader currentUser={currentUser} />
      <div>{children}</div>
      <Footer />
    </Layout>
  );
}
