const constants = {
  premiumStripeLink: "https://buy.stripe.com/cN2cQO4oMbVqgUMeUU",
  appName: "Greenside",
  authTokenName: "greenside_access_token",
  refreshTokenName: "greenside_refresh_token",
  supportEmail: "support@greensideenergy.com",
  calendlyLInk: "https://calendly.com/greenside/-demo",
  supportPhone: "1=800-555-5555",
  mobileBreakpoint: 1025,
  billdUrl: "https://go.billd.com/greensideenergy",
  disputeReasonCodes: [
    {
      value: 1,
      label: "No longer needed",
    },
    {
      value: 2,
      label: "Inaccurate website description",
    },
    {
      value: 3,
      label: "Item defective or doesn’t work",
    },
    {
      value: 4,
      label: "Bought by mistake",
    },
    {
      value: 5,
      label: "Better price available",
    },
    {
      value: 6,
      label: "Product damanged, but box ok",
    },
    {
      value: 7,
      label: "Item arrived too late",
    },
    {
      value: 8,
      label: "Missing or broken parts",
    },
    {
      value: 9,
      label: "Product & box both damaged",
    },
    {
      value: 10,
      label: "Wrong item was sent",
    },
    {
      value: 11,
      label: "Didn’t approve purchase",
    },
  ],
};

export default constants;
