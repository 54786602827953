import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import authLink from "./links/authLink";
import { createUploadLink } from "apollo-upload-client";
import apolloLogger from "apollo-link-logger";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/browser";

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_HOST || "http://localhost:8080/graphql",
  headers: {
    "keep-alive": "true",
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      Sentry.captureMessage(JSON.stringify({ message, locations, path }))
    );
  if (networkError) {
    Sentry.captureException(networkError);
  }
});

// for some reason, need to pass uri in via links like https://github.com/apollographql/apollo-link/issues/1246#issuecomment-656092271
let links = [errorLink, authLink, apolloLogger, uploadLink];

if (process.env.NODE_ENV === "production") {
  links = [errorLink, authLink, uploadLink];
}

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from(links),
});

export default client;
