// TOP LEVEL IMPORTS
import React, {Suspense} from 'react';
import {Route} from 'react-router-dom';
import Loading from 'components/common/Loading';

// EXPORTED COMPONENT
// ==============================
const PublicRoute = (props) => {
  const {
    currentUser,
    currentUserLoading,
    redirectOnAuth,
    showHeader,
    showFooter,
    component: Component,
    path,
    header,
    backgroundColor,
    layout: Layout,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      path={path}
      render={(args) => {
        return (
          <Layout
            currentUser={currentUser}
            currentUserLoading={currentUserLoading}
            backgroundColor={backgroundColor}
            showHeader={showHeader}
            header={header}
            showFooter={showFooter}
          >
            <Suspense
              fallback={
                <div style={{height: '100vh'}}>
                  <Loading />
                </div>
              }
            >
              <Component
                currentUser={currentUser}
                currentUserLoading={currentUserLoading}
                {...args}
                {...rest}
              />
            </Suspense>
          </Layout>
        );
      }}
    />
  );
};

// PROPS
// ==============================
PublicRoute.defaultProps = {
  redirectOnAuth: true,
};

// EXPORT
// ==============================
export default PublicRoute;
