// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  fragment buyerFragment on Buyer {
    id
    companyName
    dba
    phone
    website
    email
    street
    city
    state
    zip
    logo
    license
    licenseState
    approvedForProjects
  }
`;
