import {createGlobalStyle} from 'styled-components';
import theme from './theme';

export const GlobalStyle = createGlobalStyle`
  /* TOP LEVEL CSS */
  div, a, h1, h2, h3, h4, h5, p, span {
    font-family: ${theme.fontFamily};
    font-size: 16px;
  }
  html, body {
    margin: 0px;
    height: 100%;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    scroll-behavior: smooth; 
  }
 
  #root {
    height: 100%;
  }

  a {
    color: ${theme.colors.blue6};
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${theme.colors.primary4} !important;
  }
  .ant-radio-inner::after {
    background-color: ${theme.colors.primary4} !important;
  }

  /* ANTD STYLES */

  .ant-btn-primary {
    background: ${theme.colors.primary2};
    border-color: ${theme.colors.primary2};
    border-radius: 5px;
  } 

  .ant-btn:hover, .ant-btn:focus {
    color: ${theme.colors.primary2};
    background: #fff;
    border-color: ${theme.colors.primary2};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${theme.colors.blue3};
  }
  .ant-tabs-ink-bar {
    background: ${theme.colors.blue3};
  }

  .ant-tabs-tab:hover {
    color: ${theme.colors.blue4};
  }
  
  .ant-select-item-option-content {
    font-weight: 600;
    font-size: 14px;
    line-height: 40px !important;
    color: ${(p) => p.theme.colors.neutral2};
  }

  .ant-pagination-item-active a {
    color: ${(p) => p.theme.colors.primary3} !important;
  }

  .ant-pagination-item-active {
    border-color: ${(p) => p.theme.colors.primary4} !important;
  }

  .ant-pagination-item:focus, .ant-pagination-item:hover {
    border-color: ${(p) => p.theme.colors.primary4} !important;
  }

  .ant-pagination-item:focus a, .ant-pagination-item:hover a {
    color: ${(p) => p.theme.colors.primary3} !important;
  }

  .ant-select-dropdown {
    width: auto !important;
  }

  // TopMenuNavBar

  .TopMenuNavBar.ant-drawer {
    transform: none !important;
    .ant-drawer-content-wrapper {
      width: 365px !important;
      max-width: 100%;
    }

    .ant-drawer-header {
      padding-left: 50px !important;
      background: ${theme.colors.blue12};
      border-bottom: 0;
    }

    img.account_icon {
      position: absolute;
      top: 16px;
      width: 22px;
      left: 20px;
    }

    .ant-drawer-body {
      padding: 0;
    }

    .anticon-close {
      color: ${theme.colors.white} !important;
    }

    .ant-drawer-body {
      padding: 0;
    }

    .ant-drawer-title {
      color: ${theme.colors.white} !important;
    }
  }

  // TopMenuChildrenNavBar
  .TopMenuChildrenNavBar.ant-drawer {
    max-width: 100%;
    margin-top: 55px;

    .ant-drawer-header {
      display: none;
    }
    
    .ant-drawer-body {
      padding: 0;
    }
  }

  // Account Drop Down Menu
  .account-drop-down {
    margin-top: 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 5px;

    @media (max-width: 768px) {
      margin-left: -45px;
    }
  
    &:before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      transform: rotate(45deg) translateX(-50%);
      top: 15px;
      z-index: -1;
      background: ${theme.colors.white};
      position: absolute;
      border-radius: 5px;
      left: 50%;
      @media (max-width: 768px) {
        left: 65%;
      }
    }
  }

  //products-drop-down
  .products-drop-down {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
  }

  //detail-modal
  .detail-modal .ant-modal .ant-modal-content {
    padding: 66px 50px 50px;
  }

  @media (max-width: 767px) {
    .detail-modal .ant-modal {
      max-width: 100vw;
      margin: 0;
      top: 0;
      padding: 0;
    }
    .detail-modal .ant-modal .ant-modal-content {
      padding: 66px 10px 10px;
      min-height: 100vh;
    }
    .detail-modal .ant-modal .ant-modal-content .ant-modal-close {
      position: fixed;
    }
  }

  .detail-modal .ant-table-thead > tr > th {
    font-weight: bold;
    font-size: 12px;
    line-height: 26px;
    color: ${theme.colors.neutral4};
    text-align: right;
  }
  .detail-modal .ant-table-thead > tr > th:first-child {
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    color: ${theme.colors.neutral1};
  }
`;

export default theme;
