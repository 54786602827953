// TOP LEVEL IMPORTS
import React, {Suspense} from 'react';
import {Route, Redirect} from 'react-router-dom';
import Loading from 'components/common/Loading';

class SupplierRoute extends React.Component {
  render() {
    const {
      currentUser,
      path,
      noNav,
      noFooter,
      theme,
      location,
      isAllowed,
      currentUserLoading,
      component: Component,
      layout: Layout,
      ...rest
    } = this.props;

    if (currentUserLoading) {
      return <Loading />;
    }

    if (!currentUser) {
      // add redirect so we can redirect them to whatever page they were trying to enter
      return (
        <Redirect
          to={`/login?redirectTo=${location.pathname}${location.search}`}
        />
      );
    }

    if (
      currentUser &&
      currentUser.roles &&
      !currentUser.roles.includes('supplier')
    ) {
      return <Redirect to="/" />;
    }

    if (!currentUser || !currentUser.supplier || !currentUser.supplier.id) {
      return 'You need a supplier profile';
    }

    return (
      <Route
        {...rest}
        path={path}
        render={(args) => (
          <Layout {...args} {...this.props}>
            <Suspense fallback={<Loading />}>
              <Component {...args} {...this.props} />
            </Suspense>
          </Layout>
        )}
      />
    );
  }
}

export default SupplierRoute;
