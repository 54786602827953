import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
// COMPONENTS
import AppRoutes from "./routes/index";
import ErrorBoundary from "components/common/ErrorBoundary";
import SEO from "components/common/SEO";
import AuthenticationModal from "components/common/AuthenticationModal";
// LIB
import theme, { GlobalStyle } from "lib/theme";
// HOOKS
import useSentry from "lib/hooks/useSentry";
import useFullStory from "lib/hooks/useFullStory";
// GRAPHQL
import { ApolloProvider } from "@apollo/client";
import client from "ApolloClient/index";

export default function App() {
  // bring sentry in
  useSentry();
  // bring in fullstory
  useFullStory();

  useEffect(() => {
    /** GOOGLE TAG MANAGER... only run on production */
    if (process.env.NODE_ENV === "production") {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "UA-150364115-1");
    }
  }, []);

  return (
    <HelmetProvider>
      <>
        <Helmet>
          {/* 
            We want to include a noindex meta tag but only when it's not production. 
            i.e. we don't want google to index our staging site 
          */}
          {process.env.NODE_ENV !== "production" && (
            <meta name="robots" content="noindex" />
          )}
          {process.env.NODE_ENV === "production" && (
            <>
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=UA-150364115-1"
              ></script>
            </>
          )}
        </Helmet>
        <SEO />
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <ErrorBoundary>
                <>
                  <AuthenticationModal />
                  <AppRoutes />
                </>
              </ErrorBoundary>
            </BrowserRouter>
            <GlobalStyle />
          </ThemeProvider>
        </ApolloProvider>
      </>
    </HelmetProvider>
  );
}
