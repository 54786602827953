import React from 'react';
import styled from 'styled-components';
// COMPONENTS
import FormLabel from './FormLabel';
import HintText from './HintText';
import {QuestionCircleFilled, CloseCircleFilled} from '@ant-design/icons';
import Popover from 'components/common/Popover';

const QuestionIcon = styled(QuestionCircleFilled)`
  font-size: 14px;
  color: ${(p) => p.theme.colors.neutral6};
  margin-left: 6px;
  cursor: pointer;
`;

const ErrorIcon = styled(CloseCircleFilled)`
  font-size: 14px;
  color: ${(p) => p.theme.colors.red6};
  margin-left: 6px;
  cursor: pointer;
  position: relative;
  top: 1px;
`;

const Container = styled.div`
  margin-top: 22px;
  position: relative;
`;

const Required = styled.span`
  color: ${(p) => p.theme.colors.red4};
  font-size: 14px;
  position: relative;
  top: 0px;
  right: -3px;
  line-height: 10px;
`;

const ErrorText = styled(HintText)`
  color: ${(p) => p.theme.colors.red4};
`;

type FormItemProps = {
  label?: string;
  colon?: boolean;
  hint?: string;
  error?: string | null | undefined;
  children: React.ReactNode;
  containerProps?: any; // pass any props you want to the outter container of the form item
  required?: boolean;
  tooltip?: string | React.ReactNode;
};

export default function FormItem({
  label,
  colon,
  hint,
  error,
  children,
  containerProps = {},
  required,
  tooltip,
}: FormItemProps) {
  return (
    <Container {...containerProps}>
      {label && (
        <FormLabel>
          {label}
          {colon ? ':' : null}
          {required ? <Required>*</Required> : null}
          {tooltip ? (
            <Popover
              content={
                <div style={{width: 220, maxWidth: '100%'}}>{tooltip}</div>
              }
            >
              <QuestionIcon />
            </Popover>
          ) : null}
        </FormLabel>
      )}
      {children}
      {hint && <HintText>{hint}</HintText>}
      {error && (
        <ErrorText>
          <ErrorIcon /> {error}
        </ErrorText>
      )}
    </Container>
  );
}
