// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  fragment supplierFragment on Supplier {
    id
    companyName
    fee
    email
    dba
    phone
    website
    contactName
    contactPhone
    contactEmail
    street
    city
    state
    anonymous
    zip
    defaultPickupType
    logo {
      id
      url
      filename
    }
    payouts {
      businessName
      ein
      checkingAccountNumber
      routingNumber
    }
    license
    oldId
    active
  }
`;
