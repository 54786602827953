import React from 'react';
import styled from 'styled-components';
import Row from 'components/common/Row';
import Col from 'components/common/Col';
import {Link} from 'react-router-dom';
import categories from './limited-categories.json';

const DropDownItemGroup = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.neutral6};
  letter-spacing: 1.25px;
  margin-bottom: 8px;
`;

const DropDownItem = styled(Link)`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: ${(p) => p.theme.colors.neutral1};
  &:hover {
    color: ${(p) => p.theme.colors.blue3};
  }
`;

const ProductsDropDownItemContainer = styled.div`
  display: flex;
  padding: 16px;
  background: ${(p) => p.theme.colors.white};
  width: 1000px;
  max-width: 100%;
`;

const ProductsFeaturedSection = styled.div`
  width: 190px;
  max-width: 100%;
`;

const SeparatorVertical = styled.div`
  height: 100%;
  width: 2px;
  background: ${(p) => p.theme.colors.white1};
  margin: 0 14px;
`;

const DropDownItemSection = styled.div`
  margin: 6px 0;
  align-items: center;
  display: flex;
`;

const AllCategoriesSection = styled.div`
  width: 800px;
  max-width: 100%;
`;

const FEATURED_CATEGORIES = [
  {
    label: 'Panels',
    link: '/panels',
  },
  {
    label: 'Inverters',
    link: '/inverters',
  },
  {
    label: 'Racking',
    link: '/racking',
  },
  {
    label: 'Balance of Systems',
    link: '/balance-of-systems',
  },
  {
    label: 'Battery Accessories',
    link: '/battery-accessories',
  },
  {
    label: 'Charge Controllers',
    link: '/charge-controllers',
  },
];

export default function ProductsFlyout() {
  return (
    <ProductsDropDownItemContainer className="products-drop-down">
      <ProductsFeaturedSection>
        <DropDownItemSection>
          <DropDownItemGroup>FEATURED</DropDownItemGroup>
        </DropDownItemSection>
        {FEATURED_CATEGORIES.map((item, i) => {
          return (
            <DropDownItemSection key={item.link + i}>
              <DropDownItem to={item.link}>{item.label}</DropDownItem>
            </DropDownItemSection>
          );
        })}
      </ProductsFeaturedSection>
      <SeparatorVertical />
      <AllCategoriesSection>
        <DropDownItemSection>
          <DropDownItemGroup>CATEGORIES</DropDownItemGroup>
        </DropDownItemSection>
        <Row style={{maxWidth: '100%'}}>
          {categories
            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)) // alphabetize
            .map((cat) => {
              return (
                <Col xs={12} sm={8} key={cat.id}>
                  <DropDownItemSection>
                    <DropDownItem to={`/${cat.slug}`}>{cat.name}</DropDownItem>
                  </DropDownItemSection>
                </Col>
              );
            })}
        </Row>
      </AllCategoriesSection>
    </ProductsDropDownItemContainer>
  );
}
