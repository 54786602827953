/* This example requires Tailwind CSS v2.0+ */
import "../../index.css";
import Pricing2 from "./Pricing";
import Hero from "./Hero";
import Features from "./Features";
import Footer from "./Footer";
import LogoCloud from "./LogoCloud";
import ThreeFeatures from "./ThreeFeatures";
import HowItWorks from "../public-landing/HowItWorks";
import WhyGreenside from "../public-landing/WhyGreenside";
import TraditionalWay from "../public-landing/TraditionalWay";
import HowWeDoIt from "../public-landing/HowWeDoIt";
import styled from "styled-components";

const Wrapper = styled.div`
  min-height: 100vh;
  a {
    &:hover {
      color: #fff;
    }
  }
`;

export default function Example() {
  return (
    <Wrapper>
      <Hero />
      <LogoCloud />
      <TraditionalWay />
      <HowWeDoIt demoBtn={false} />
      <WhyGreenside />
      {/* */}
      {/* <HowItWorks /> */}
      <ThreeFeatures />
      <Pricing2 />
      <Footer />
    </Wrapper>
  );
}
