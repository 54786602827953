import React from "react";
import styled from "styled-components";
import { Carousel } from "antd";

const testimonials = [
  {
    quote:
      "I would put in orders over the phone from 4-5 different distributors. On Greenside it's so easy, I order all in one place.",
    name: "Ryan Potter - Vice President of Renewable Energy Services",
  },
  {
    quote:
      "I used to spend hours comparing prices from suppliers. After I found out were paying 18% less than me. Now, with Greenside I know I'm always getting the best prices.",
    name: "Rick -  Solar Developer",
  },
  {
    quote:
      "Greenside allows me to see how much I'm spending and see all my invoices in one place for all my vendors. Keeping track of all my purchases used to be a mess.",
    name: "David - Procurement Specialist",
  },
];

const Wrapper = styled.div`
  background-color: ${(p) => p.theme.colors.blue12};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 1200px) {
    height: 446px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 60px 0;
`;

const Title = styled.h1`
  font-size: 20px;
  color: ${(p) => p.theme.colors.neutral6};
  line-height: 2.11;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  margin-bottom: 40px;
  font-weight: 500;
`;

const CarouselItem = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: 300px;
    width: 500px;
  }

  @media (max-width: 500px) {
    height: 400px;
    width: 300px;
  }
`;

const Quote = styled.h1`
  font-size: 24px;
  color: ${(p) => p.theme.colors.white};
  font-weight: 700;
  line-height: 1.5;

  &::before {
    content: '"';
    font-size: 4em;
    position: relative;
    top: -16px;
    left: -2px;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
    transform: rotate(45deg);
    color: ${(p) => p.theme.colors.neutral6};
  }
`;

const Citation = styled.p`
  font-size: 18px;
  color: ${(p) => p.theme.colors.white};
`;

const CarouselContainer = styled.div`
  height: 200px;
  width: 814px;

  @media (max-width: 768px) {
    height: 300px;
    width: 500px;
  }

  @media (max-width: 500px) {
    height: 400px;
    width: 300px;
  }
`;

const WhyGreenside = () => {
  return (
    <Wrapper>
      <Container>
        <Title>Why Greenside?</Title>
        <CarouselContainer>
          <Carousel autoplay>
            {testimonials.map((testimonial, idx) => (
              <div key={idx}>
                <CarouselItem>
                  <Quote>{testimonial.quote}</Quote>
                  <Citation>{testimonial.name}</Citation>
                </CarouselItem>
              </div>
            ))}
          </Carousel>
        </CarouselContainer>
      </Container>
    </Wrapper>
  );
};

export default WhyGreenside;
