import styled from "styled-components";
import Button from "components/common/Button";
import compareQuotesSvg from "./compare_quotes.svg";
import trustedDistributorsSvg from "./trusted_distributors.svg";
import tradeAssuranceSvg from "./trade_assurance.svg";
import config from "lib/constants";

const sellingPoints = [
  {
    svg: compareQuotesSvg,
    heading: "Visibility and Ordering in One Place",
    text: "Manage all your inventory, preferred materials, and orders in one place. Track shipments, handle returns, and manage spending.",
  },
  {
    svg: trustedDistributorsSvg,
    heading: "Expert PV Specialists and Support",
    text: "Access the expertise and data-driven recommendations of our procurement team. Use our two-way messaging – contractors and suppliers love it!",
  },
  {
    svg: tradeAssuranceSvg,
    heading: "Industry Leading Prices",
    text: "Benefit from large-scale buying power to receive exclusive prices on your same high-quality materials from authorized vendors.",
  },
];

const Background = styled.section`
  background-color: ${(p) => p.theme.colors.neutral10};
`;

const Wrapper = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 60px 60px;

  @media (min-width: 1200px) {
    height: 1042px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 60px 0;

  @media (min-width: 1200px) {
    width: 60%;
  }

  @media (max-width: 1200px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 95%;
  }
`;

const Title = styled.h1`
  font-size: 32px;
  color: ${(p) => p.theme.colors.neutral2};
  font-weight: 700;
  line-height: 1.18;
  max-width: 681px;
`;

const ContentContainer = styled.div`
  margin: 40px 0;
`;

const SellingPoint = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid ${(p) => p.theme.colors.neutral9};
  align-items: center;
  padding-bottom: 40px;
  margin-bottom: 40px;

  @media (max-width: 425px) {
    flex-direction: column-reverse;
    text-align: center;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.h1`
  font-size: 24px;
  color: ${(p) => p.theme.colors.neutral2};
  font-weight: 700;
  line-height: 1.58;
`;

const Text = styled.p`
  font-size: 18px;
  color: ${(p) => p.theme.colors.neutral2};
`;

const Img = styled.img`
  margin-left: 83px;

  @media (max-width: 425px) {
    margin-left: 0;
    margin-bottom: 15px;
  }
`;

const BtnContainer = styled.div`
  align-self: center;
`;

const HowWeDoIt = ({ demoBtn = true }) => {
  return (
    <Background>
      <Wrapper>
        <Container>
          <Title>How we do it</Title>
          <ContentContainer>
            {sellingPoints.map((point, idx) => (
              <SellingPoint key={idx}>
                <TextContainer>
                  <Heading>{point.heading}</Heading>
                  <Text>{point.text}</Text>
                </TextContainer>
                <Img src={point.svg} alt={point.heading} />
              </SellingPoint>
            ))}
          </ContentContainer>
          {demoBtn && (
            <BtnContainer>
              <Button
                style={{ width: 210, height: 48, fontSize: 18 }}
                onClick={() => {
                  window.open(config.calendlyLInk, "_newtab" + Date.now());
                }}
              >
                Schedule a demo
              </Button>
            </BtnContainer>
          )}
        </Container>
      </Wrapper>
    </Background>
  );
};

export default HowWeDoIt;
