import React from 'react';
import styled from 'styled-components';
import {useHistory, Link} from 'react-router-dom';
import Header from './Header';
import Footer from 'components/common/Footer';
import Button from 'components/common/Button';
// LIB
import heroImg from './bg-img.svg';

const Container = styled.div`
  width: 1170px;
  margin: 0 auto;
  padding: 0 15px;

  @media (max-width: 1200px) {
    width: 950px;
  }

  @media (max-width: 992px) {
    width: 750px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Hero = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    align-items: flex-end;
    height: 1000px;
  }
`;

const HeroImg = styled.img`
  position: absolute;
  right: -300px;
  top: 0;
  z-index: -1;
  height: 100vh;
`;

const HeroTitle = styled.h1`
  font-size: 80px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${(p) => p.theme.colors.neutral9};
  margin: 0;
`;

const HeroSubtitle = styled.h2`
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: ${(p) => p.theme.colors.blue13};
  margin: 0 0 15px;
  line-height: 1.25;
`;

const HeroText = styled.div`
  width: 100%;
  max-width: 550px;
  position: relative;
  top: -80px;
  @media (max-width: 768px) {
    max-width: unset;
    text-align: center;
  }
`;

const HeroDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${(p) => p.theme.colors.neutral2};
  margin-bottom: 5px;
  width: 75%;
`;

const HeroBtns = styled.div`
  margin: 30px 0 0;
  justify-content: flex-start;
  display: flex;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export default function ErrorPage() {
  const history = useHistory();
  return (
    <div style={{position: 'relative'}}>
      <Header />
      <HeroImg src={heroImg} />
      <Container>
        <Hero>
          <HeroText>
            <HeroTitle>Error</HeroTitle>
            <HeroSubtitle>Something went wrong...</HeroSubtitle>
            <HeroDescription>
              We're sorry but it seems that has gone wrong. Could you refersh
              and try again? If you continue to encounter issues please reach
              out to <Link to="/support">support here</Link>.
            </HeroDescription>
            <HeroBtns>
              <Button style={{width: 220}} onClick={() => history.push('/')}>
                Go to home page
              </Button>
            </HeroBtns>
          </HeroText>
        </Hero>
      </Container>
      <Footer />
    </div>
  );
}
