import styled from "styled-components";
import { Link } from "react-router-dom";
// COMPONENTS
import successSVG from "./success.svg";
import heroImg from "./hero-img.svg";

const Outter = styled.div`
  position: relative;
  min-height: 100vh;
  /* background: ${(p) => p.theme.colors.neutral10}; */
`;

const Container = styled.div`
  min-height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
  padding: 0 15px;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
    justify-content: left;
  }

  @media (min-width: 992px) {
    max-width: 1200px;
  }
`;

const SuccessCaption = styled.p`
  margin: 0px;
  color: ${(p) => p.theme.colors.primary3};
  font-size: 15px;
  margin-top: 16px;
  line-height: 150%;
  a {
    color: ${(p) => p.theme.colors.primary2};
    font-size: 15px;
    text-decoration: underline;
    &:hover {
      color: ${(p) => p.theme.colors.primary1};
      text-decoration: underline;
    }
  }
`;

const SuccessTitle = styled.p`
  margin: 0px;
  color: ${(p) => p.theme.colors.primary2};
  font-size: 18px;
  margin-top: 0px;
  font-weight: 600;
`;

const HeroImg = styled.img`
  position: absolute;
  height: 100%;
  right: -20%;
  z-index: 1;
  top: 0px;
  @media (max-width: 576px) {
    display: none;
  }
`;

const SuccessContainer = styled.div`
  background: ${({ theme }) => theme.colors.primary10};
  width: 650px;
  padding: 20px;
  border-radius: 5px;
  max-width: 100%;
  display: flex;
`;

export default function PublicSupport({ currentUser, currentUserLoading }) {
  return (
    <Outter>
      <Container style={{ minHeight: 500 }}>
        <SuccessContainer>
          <img
            src={successSVG}
            alt="success"
            style={{ position: "relative", top: 3, height: 24 }}
          />
          <div style={{ marginLeft: 16 }}>
            <SuccessTitle>
              Your Greenside Premium subscription was successful!
            </SuccessTitle>
            <SuccessCaption>
              Your account manager will reach out within the next business day.
            </SuccessCaption>
            <SuccessCaption>
              In the mean time, if you have questions, you can contact us{" "}
              <Link to="/support">here</Link>.
            </SuccessCaption>
            <SuccessCaption>
              If you’d like to get quotes for your next project, you can post it{" "}
              <Link to="/project/guide/start">here</Link>.
            </SuccessCaption>
          </div>
        </SuccessContainer>
      </Container>
      <HeroImg src={heroImg} alt="Hero Image" />
    </Outter>
  );
}
