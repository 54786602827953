import React, { lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import get from "lodash.get";
// COMPONENTS
import Dropdown from "components/common/Dropdown";
import ProductsFlyOut from "./ProductsFlyOut";
// LIB
import LogoImg from "lib/media/Greenside_logo.svg";

/**
 * LAZY LOAD SOME HEADER COMPONENTS
 */
const CheckoutLink = lazy(() => import("./CheckoutLink"));
const SearchInput = lazy(() => import("./SearchInput"));
const NavMenu = lazy(() => import("./NavMenu"));
const TopHeaderRightSection = lazy(() => import("./TopHeaderRightSection"));

const Header = styled.div`
  width: 100%;
`;

const TopHeader = styled.div`
  width: 100%;
  padding: 16px 5px 8px 5px;
  background: ${(p) => p.theme.colors.blue11};
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const LogoSection = styled(Link)`
  margin: auto 14px;
`;

const Logo = styled.img`
  height: 48px;
  width: auto;
`;

const TopHeaderRightSectionMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: inherit;
  }
`;

const TopHeaderLeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const BottomHeader = styled.div`
  background: ${(p) => p.theme.colors.blue11};
  padding-left: 230px;
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavItemDropdownTitle = styled(Link)`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${(p) => p.theme.colors.neutral10} !important;
  margin: 5px 0;
  padding: 8px 32px 8px 0;

  &:hover {
    color: ${(p) => p.theme.colors.neutral8} !important;
  }

  @media (max-width: 768px) {
    padding: 8px 0;
    ${(p) =>
      p.mobile &&
      css`
        display: none;
      `}
  }
`;

export default function EcomHeader({ currentUser }) {
  const lineItems = get(currentUser, "buyer.openCart.lineItems", null);

  return (
    <Suspense fallback={<div />}>
      <Header>
        <TopHeader>
          <TopHeaderLeftSection>
            <NavMenu currentUser={currentUser} />
            <LogoSection to="/">
              <Logo src={LogoImg} alt="Greenside Energy Logo" />
            </LogoSection>
          </TopHeaderLeftSection>
          <TopHeaderRightSectionMobile>
            <CheckoutLink currentUser={currentUser} lineItems={lineItems} />
          </TopHeaderRightSectionMobile>
          <SearchInput />
          <TopHeaderRightSection currentUser={currentUser} />
        </TopHeader>
        <BottomHeader>
          {currentUser && (
            <NavItemDropdownTitle
              mobile
              to={`/buyer/home`}
              data-testid="ecomheader-my-projects-link"
            >
              My Projects
            </NavItemDropdownTitle>
          )}
          {currentUser && (
            <NavItemDropdownTitle mobile to={`/orders`}>
              Orders
            </NavItemDropdownTitle>
          )}
          <Dropdown overlay={ProductsFlyOut}>
            <NavItemDropdownTitle to="/">Products</NavItemDropdownTitle>
          </Dropdown>
          <NavItemDropdownTitle to="/brands">Brands</NavItemDropdownTitle>
          <NavItemDropdownTitle to={`/procurement`}>
            Request a Quote
          </NavItemDropdownTitle>
          <NavItemDropdownTitle mobile to={`/support`}>
            Customer Support
          </NavItemDropdownTitle>
        </BottomHeader>
      </Header>
    </Suspense>
  );
}
