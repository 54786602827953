import styled from 'styled-components';

const Caption = styled.span`
  margin: 0px;
  bottom: 0;
  font-weight: 400;
  font-family: ${(p) => p.theme.fontFamily};
  display: ${(p) => (p.display ? p.display : 'block')};
  color: ${(p) => (p.color ? p.color : p.theme.colors.neutral4)};
  font-size: ${(p) => (p.style.fontSize ? p.style.fontSize : '14px')};
`;

Caption.defaultProps = {
  style: {}, // TODO: figure out proper way to pass in additional style prop to styled-component wrapper
};

export default Caption;
