import React from 'react';
import styled from 'styled-components';

// STYLE-COMPONENTS
// ===================================
const Container = styled.div`
  /* height: 100%; */
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  background-color: ${(p) => (p.backgroundColor ? p.backgroundColor : null)};
`;

// EXPORT
// ===================================
export default function PublicLayout({children, header, backgroundColor}) {
  return (
    <Container id="public-layout" backgroundColor={backgroundColor}>
      {header && header}
      {children}
    </Container>
  );
}
