import {gql} from '@apollo/client';

export default gql`
  query publicSystemSettings {
    publicSystemSettings {
      billdThreshold
      minimumPriceForServiceFee
      serviceFeePrice
    }
  }
`;
