// TOP LEVEL IMPORTS
import React, { lazy, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import ms from "ms";
// PUBLIC
import PublicRoute from "components/route-components/PublicRoute";
import AdminRoute from "components/route-components/AdminRoute";
import BuyerRoute from "components/route-components/BuyerRoute";
import SupplierRoute from "components/route-components/SupplierRoute";
// LAYOUTS
import PublicLayout from "components/layouts/PublicLayout";
import EcomLayout from "components/layouts/EcomLayout";
import SupplierLayout from "components/layouts/SupplierLayout";
import BuyerLayout from "components/layouts/BuyerLayout";
import AdminLayout from "components/layouts/AdminLayout";
import Premium from "./public-premium";
import PremiumSuccess from "./public-premium-success";
// COMPONENTS
import EcomHeader from "components/common/EcomHeader";
import ErrorContent from "components/common/ErrorContent";
// ECOM
import EcomHome from "routes/ecom-home";
// APOLLO
import { useQuery } from "@apollo/client";
import CURRENT_USER from "ApolloClient/Queries/currentUser";
import PUBLIC_SYSTEM_SETTINGS from "ApolloClient/Queries/publicSystemSettings";
// LIB
import categories from "lib/constants/categories.json";
import helpers from "lib/helpers/index.js";

/**
  LAZY LOAD ADMIN COMPONENTS
*/
const AdminUsers = lazy(() => import("../routes/admin-users"));
const AdminSuppliers = lazy(() => import("../routes/admin-suppliers"));
const AdminBuyers = lazy(() => import("../routes/admin-buyers"));
const AdminProjects = lazy(() => import("../routes/admin-projects"));
const AdminProjectsDetail = lazy(() =>
  import("../routes/admin-projects-detail")
);
const AdminProducts = lazy(() => import("../routes/admin-products"));
const AdminOrders = lazy(() => import("../routes/admin-orders"));
const AdminSupport = lazy(() => import("../routes/admin-support"));
const AdminSettings = lazy(() => import("../routes/admin-settings"));
const AdminDashboard = lazy(() => import("../routes/admin-dashboard"));

/**
  LAZY LOAD OTHER RANDOM COMPONENTS
*/
const PublicPdf = lazy(() => import("../routes/public-pdf"));
const PageNotFound = lazy(() => import("../routes/public-not-found"));
const PublicQuoteRequest = lazy(() => import("../routes/public-quote-request"));
const AboutUs = lazy(() => import("../routes/public-about-us"));
const QuoteRequestForm = lazy(() => import("../routes/public-quote-request"));
const PublicCareers = lazy(() => import("../routes/public-careers"));
const EcomSearch = lazy(() => import("./ecom-search"));
const EcomCart = lazy(() => import("../routes/ecom-cart"));
const EcomCheckout = lazy(() => import("../routes/ecom-checkout"));
const EcomShareableCheckout = lazy(() =>
  import("../routes/ecom-checkout-shareable")
);
const EcomProductDetail = lazy(() => import("../routes/ecom-product-detail"));
const EcomBrands = lazy(() => import("../routes/ecom-brands"));
// BUYER
const BuyerHome = lazy(() => import("../routes/buyer-home"));
const BuyerSettings = lazy(() => import("../routes/buyer-settings"));
const BuyerProjectDetail = lazy(() => import("../routes/buyer-project-detail"));
const BuyerOrders = lazy(() => import("../routes/buyer-orders"));
const BuyerProjectConfigurator = lazy(() =>
  import("../routes/buyer-project-configurator")
);
const BuyerCompareQuotes = lazy(() => import("../routes/buyer-compare-quotes"));
const EcomCheckoutShareableSuccess = lazy(() =>
  import("../routes/ecom-checkout-shareable-success")
);

// SUPPLIER
const SupplierHome = lazy(() => import("../routes/supplier-home"));
const SupplierSettings = lazy(() => import("../routes/supplier-settings"));
const SupplierProjectDetail = lazy(() =>
  import("../routes/supplier-project-detail")
);
const SupplierQuotes = lazy(() => import("../routes/supplier-quotes"));
const SupplierOrders = lazy(() => import("../routes/supplier-orders"));
// PUBLIC
const PublicLanding = lazy(() => import("../routes/public-landing"));
const PublicSupport = lazy(() => import("../routes/public-support"));
const PublicPrequalification = lazy(() =>
  import("../routes/public-prequalification")
);
const PublicLegal = lazy(() => import("../routes/public-legal"));
const PublicSupplier = lazy(() => import("../routes/public-supplier"));
const PublicQuoteInvite = lazy(() => import("../routes/public-quote-invite"));
const PublicQuoteInviteAnyone = lazy(() =>
  import("../routes/public-quote-invite-anyone")
);
// AUTH
const AuthLoginRoute = lazy(() => import("../routes/auth-login"));
const AuthForgotPassword = lazy(() => import("../routes/auth-forgot-password"));
const ResetPassword = lazy(() => import("../routes/auth-reset-password"));
const BuyerSignup = lazy(() => import("../routes/auth-buyer-signup"));
const AuthVerifyEmail = lazy(() => import("../routes/auth-verify-email"));

export default function AppRoutes() {
  const { data, loading, error } = useQuery(CURRENT_USER, {
    errorPolicy: "all",
    fetchPolicy: "network-only",
    pollInterval: ms("45s"), // rerun query every X seconds
  });

  /** GRAB OUR SETTINGS FROM BACKEND */
  const {
    data: publicSystemSettingsQuery,
    loading: publicSystemSettingsLoading,
  } = useQuery(PUBLIC_SYSTEM_SETTINGS);

  let currentUser = data && data.currentUser;

  useEffect(() => {
    if (currentUser) {
      helpers.identifyUser(currentUser.id);
    }
  }, [currentUser]);

  if (error && error.message) {
    return <ErrorContent />;
  }

  let sharedProps = {
    currentUser,
    currentUserLoading: loading,
    publicSystemSettings:
      !publicSystemSettingsLoading &&
      publicSystemSettingsQuery?.publicSystemSettings,
  };

  /**
   * The categories are used in the URL, and are semi dynamic, so we will map over them here to pass to the serach route
   */
  const categoryLinks = categories.map(({ slug }) => {
    return `/${slug}`;
  });

  return (
    <Switch>
      <Route path="/home" />
      {/* ECOM */}
      <PublicRoute
        exact
        layout={EcomLayout}
        path="/cart"
        component={EcomCart}
        {...sharedProps}
      />
      <PublicRoute
        exact
        layout={PublicLayout}
        path="/quote-invite/:token"
        component={PublicQuoteInvite}
        {...sharedProps}
      />
      <PublicRoute
        exact
        layout={PublicLayout}
        path="/quote-invite/open/:projectId"
        component={PublicQuoteInviteAnyone}
        {...sharedProps}
      />
      <PublicRoute
        exact
        layout={EcomLayout}
        path="/brands"
        component={EcomBrands}
        {...sharedProps}
      />
      <PublicRoute
        {...sharedProps}
        exact
        layout={PublicLayout}
        path="/checkout"
        component={EcomCheckout}
      />
      <PublicRoute
        {...sharedProps}
        exact
        layout={PublicLayout}
        path="/premium"
        component={Premium}
      />
      <PublicRoute
        {...sharedProps}
        exact
        layout={EcomLayout}
        path="/premium-success"
        component={PremiumSuccess}
      />
      <PublicRoute
        {...sharedProps}
        exact
        layout={PublicLayout}
        path="/public-checkout"
        component={EcomShareableCheckout}
      />
      <PublicRoute
        {...sharedProps}
        exact
        layout={PublicLayout}
        path="/public-checkout/success"
        component={EcomCheckoutShareableSuccess}
      />
      <PublicRoute
        exact
        layout={EcomLayout}
        path={["/search", "/brands/:brandSlug", ...categoryLinks]} // we'll have to add search pages as we go
        component={EcomSearch}
        {...sharedProps}
      />
      <PublicRoute
        exact
        layout={EcomLayout}
        path="/project/guide/start"
        component={PublicQuoteRequest}
        {...sharedProps}
      />
      <PublicRoute
        exact
        layout={EcomLayout}
        path={["/solar/:slug", "/market/:slug"]}
        component={EcomProductDetail}
        {...sharedProps}
      />
      <PublicRoute
        exact
        layout={PublicLayout}
        {...sharedProps}
        path="/pdf"
        component={PublicPdf}
      />
      <PublicRoute
        exact
        layout={PublicLayout}
        {...sharedProps}
        path="/new-project"
        header={<EcomHeader {...sharedProps} />}
        component={BuyerProjectConfigurator}
      />
      {/* BUYER ROUTES */}
      <BuyerRoute
        exact
        layout={BuyerLayout}
        path="/buyer/home"
        header={<EcomHeader {...sharedProps} />}
        {...sharedProps}
        component={BuyerHome}
      />
      <BuyerRoute
        exact
        layout={BuyerLayout}
        path="/orders"
        header={<EcomHeader {...sharedProps} />}
        {...sharedProps}
        component={BuyerOrders}
      />
      <BuyerRoute
        exact
        layout={BuyerLayout}
        path="/buyer/settings"
        header={<EcomHeader {...sharedProps} />}
        {...sharedProps}
        component={BuyerSettings}
      />
      <BuyerRoute
        exact
        layout={PublicLayout}
        path="/buyer/compare-quotes"
        {...sharedProps}
        component={BuyerCompareQuotes}
      />
      <BuyerRoute
        exact
        layout={BuyerLayout}
        path="/buyer/projects/:id"
        header={<EcomHeader {...sharedProps} />}
        {...sharedProps}
        component={BuyerProjectDetail}
      />
      {/* SUPPLIER ROUTES */}
      <SupplierRoute
        {...sharedProps}
        exact
        layout={SupplierLayout}
        path="/supplier/home"
        component={SupplierHome}
      />
      <SupplierRoute
        {...sharedProps}
        exact
        layout={SupplierLayout}
        path="/supplier/orders"
        component={SupplierOrders}
      />
      <SupplierRoute
        {...sharedProps}
        exact
        layout={SupplierLayout}
        path="/supplier/settings"
        component={SupplierSettings}
      />
      <SupplierRoute
        {...sharedProps}
        exact
        layout={SupplierLayout}
        path="/supplier/projects/:id"
        component={SupplierProjectDetail}
      />
      <SupplierRoute
        {...sharedProps}
        exact
        layout={SupplierLayout}
        path="/supplier/quotes"
        component={SupplierQuotes}
      />

      {/* ADMIN ROUTES */}
      <AdminRoute
        exact
        layout={AdminLayout}
        path="/admin/users"
        {...sharedProps}
        component={AdminUsers}
      />
      <AdminRoute
        exact
        layout={AdminLayout}
        path="/admin/dashboard"
        {...sharedProps}
        component={AdminDashboard}
      />
      <AdminRoute
        exact
        layout={AdminLayout}
        path="/admin/settings"
        {...sharedProps}
        component={AdminSettings}
      />
      <AdminRoute
        exact
        layout={AdminLayout}
        path="/admin/suppliers"
        {...sharedProps}
        component={AdminSuppliers}
      />
      <AdminRoute
        exact
        layout={AdminLayout}
        path="/admin/buyers"
        {...sharedProps}
        component={AdminBuyers}
      />
      <AdminRoute
        exact
        layout={AdminLayout}
        path="/admin/orders"
        {...sharedProps}
        component={AdminOrders}
      />
      <AdminRoute
        exact
        layout={AdminLayout}
        path="/admin/projects/:id"
        {...sharedProps}
        component={AdminProjectsDetail}
      />
      <AdminRoute
        exact
        layout={AdminLayout}
        path="/admin/projects"
        {...sharedProps}
        component={AdminProjects}
      />
      <AdminRoute
        exact
        layout={AdminLayout}
        path="/admin/products"
        {...sharedProps}
        component={AdminProducts}
      />
      <AdminRoute
        exact
        layout={AdminLayout}
        path="/admin/support"
        {...sharedProps}
        component={AdminSupport}
      />
      <AdminRoute
        exact
        layout={AdminLayout}
        path="/admin"
        {...sharedProps}
        component={AdminUsers}
      />

      {/* PUBLIC ROUTES */}
      <PublicRoute
        exact
        layout={PublicLayout}
        path="/login"
        {...sharedProps}
        component={AuthLoginRoute}
      />
      <PublicRoute
        exact
        layout={PublicLayout}
        {...sharedProps}
        path="/forgot-password"
        component={AuthForgotPassword}
      />
      <PublicRoute
        exact
        layout={PublicLayout}
        {...sharedProps}
        path={["/buyer-signup", "/signup"]}
        component={BuyerSignup}
      />
      <PublicRoute
        exact
        layout={PublicLayout}
        {...sharedProps}
        path="/reset-password/:token"
        component={ResetPassword}
      />
      <PublicRoute
        exact
        layout={PublicLayout}
        path="/new-quote"
        {...sharedProps}
        component={QuoteRequestForm}
      />
      <PublicRoute
        exact
        layout={PublicLayout}
        header={<EcomHeader {...sharedProps} />}
        path={["/support", "/contact"]}
        {...sharedProps}
        component={PublicSupport}
      />
      <PublicRoute
        exact
        layout={PublicLayout}
        header={<EcomHeader {...sharedProps} />}
        path={["/prequalify"]}
        {...sharedProps}
        component={PublicPrequalification}
      />

      <PublicRoute
        exact
        layout={EcomLayout}
        path={["/site/about-us", "/about-us", "/about"]}
        {...sharedProps}
        component={AboutUs}
      />
      <PublicRoute
        exact
        layout={PublicLayout}
        header={<EcomHeader {...sharedProps} />}
        path={["/site/careers", "/careers", "/help"]}
        {...sharedProps}
        component={PublicCareers}
      />
      <PublicRoute
        exact
        layout={PublicLayout}
        path={[
          "/legal",
          "/terms",
          "/privacy",
          "/gdpr",
          "/cookie",
          "/returns",
          "/shipping",
          // include old paths in case they were cached by google
          "/site/terms",
          "/site/gdpr",
          "/site/cookie",
          "/site/returns",
          "/site/privacy",
          "/site/shipping",
        ]}
        header={<EcomHeader {...sharedProps} />}
        {...sharedProps}
        component={PublicLegal}
      />
      <PublicRoute
        exact
        layout={EcomLayout}
        path={["/suppliers", "/site/distribution"]}
        {...sharedProps}
        component={PublicSupplier}
      />
      <PublicRoute
        exact
        layout={PublicLayout}
        path={["/procurement", "/"]}
        {...sharedProps}
        component={PublicLanding}
      />

      <PublicRoute
        exact
        layout={PublicLayout}
        path="/verify-email/:token"
        component={AuthVerifyEmail}
        {...sharedProps}
      />
      <PublicRoute
        exact
        layout={EcomLayout}
        path="/marketplace"
        component={EcomHome}
        {...sharedProps}
      />
      {/* 404 NOT FOUND */}
      <PublicRoute
        exact
        layout={PublicLayout}
        component={PageNotFound}
        {...sharedProps}
      />
    </Switch>
  );
}
