// TOP LEVEL IMPORTS
import { ReactChild, CSSProperties, ReactChildren } from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const getBackgroundColor = ({
  grey,
  disabled,
  secondary,
  theme,
  yellow,
  danger,
}: {
  disabled?: boolean;
  secondary?: boolean;
  danger?: boolean;
  theme: any;
  grey?: boolean;
  yellow?: boolean;
}) => {
  if (disabled) {
    return theme.colors.neutral8;
  }
  if (danger) {
    return theme.colors.red5;
  }
  if (grey) {
    return theme.colors.neutral10;
  }
  if (secondary) {
    return theme.colors.primary10;
  }
  if (yellow) {
    return theme.colors.yellow10;
  }

  return theme.colors.primary2;
};

const getTextColor = ({
  grey,
  disabled,
  yellow,
  secondary,
  theme,
}: {
  disabled?: boolean;
  secondary?: boolean;
  danger?: boolean;
  theme: any;
  grey?: boolean;
  yellow?: boolean;
}) => {
  if (disabled) {
    return theme.colors.neutral7;
  }
  if (grey) {
    return theme.colors.neutral4;
  }
  if (secondary) {
    return theme.colors.primary1;
  }
  if (yellow) {
    return theme.colors.yellow1;
  }
  return '#fff';
};

const getHoverBackgroundColor = ({
  grey,
  disabled,
  secondary,
  theme,
  danger,
  yellow,
}: {
  disabled?: boolean;
  secondary?: boolean;
  danger?: boolean;
  theme: any;
  grey?: boolean;
  yellow?: boolean;
}) => {
  if (disabled) {
    return theme.colors.neutral8;
  }
  if (danger) {
    return theme.colors.red3;
  }
  if (grey) {
    return theme.colors.neutral9;
  }
  if (secondary) {
    return theme.colors.primary9;
  }
  if (yellow) {
    return theme.colors.yellow9;
  }
  return theme.colors.primary1;
};

const PrimaryButtonContainer = styled.button<{
  fullWidth?: boolean;
  style?: CSSProperties;
}>`
  width: ${(p) => {
    if (p.fullWidth) return '100%';
    if (p?.style?.width) return p.style.width;
    return 'inherit';
  }};
  display: inline-block;
  justify-content: center;
  height: 40px;
  border-radius: 4px;
  background-color: ${(props) => getBackgroundColor(props)};
  border: 0px;
  line-height: 40px;
  padding-right: 16px;
  padding-left: 16px;
  /* text-transform: uppercase; */
  letter-spacing: 0.025em;
  align-items: center;
  /* box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); */
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  :hover {
    cursor: ${(p) => {
      if (p.disabled) {
        return 'not-allowed';
      }
      return 'pointer';
    }};
    background-color: ${(props) => getHoverBackgroundColor(props)};
  }
  :focus {
    outline: 0;
  }
  &:active {
    transform: translatey(1px);
  }
`;

const ButtonText = styled.div<{
  disabled?: boolean;
  secondary?: boolean;
  danger?: boolean;
  grey?: boolean;
  yellow?: boolean;
}>`
  height: 20px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: center;
  color: ${(props) => getTextColor(props)};
`;

type PrimaryButtonProps = {
  disabled?: boolean;
  secondary?: boolean;
  danger?: boolean;
  grey?: boolean;
  yellow?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  style?: CSSProperties;
  children:
    | ReactChild
    | ReactChildren
    | ReactChild[]
    | ReactChildren[]
    | string
    | boolean
    | null
    | any;
  onClick?: any;
  id?: string;
  className?: string;
};

const PrimaryButton = (props: PrimaryButtonProps) => (
  <PrimaryButtonContainer
    {...props}
    aria-label={props['aria-label'] || 'Button'}
    data-testid={props['data-testid']}
  >
    <ButtonText
      disabled={props.disabled}
      secondary={props.secondary}
      danger={props.danger}
      grey={props.grey}
      yellow={props.yellow}
    >
      {!props.loading ? props.children : <LoadingOutlined />}
    </ButtonText>
  </PrimaryButtonContainer>
);

PrimaryButton.defaultProps = {
  disabled: false,
  type: 'button',
};

export default PrimaryButton;
