import React, {useState} from 'react';
import queryString from 'query-string';
import {useHistory, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import ms from 'ms';
// COMPONENTS
import message from 'components/common/message';
import notification from 'components/common/notification';
import LoginForm from 'components/forms/LoginForm';
import BuyerSignupForm from 'components/forms/BuyerSignupForm';
import {CloseOutlined} from '@ant-design/icons';
// LIB
import ErrorHelpers from 'lib/helpers/ErrorHelpers';
import constants from 'lib/constants';
import logoSVG from 'lib/media/logo.svg';
// APOLLO
import client from 'ApolloClient/index';
import CURRENT_USER from 'ApolloClient/Queries/currentUser';
import SIGNUP_BUYER from 'ApolloClient/Mutations/signupBuyer';
import AUTHENTICATE from 'ApolloClient/Mutations/authenticate';
import {useMutation} from '@apollo/client';
import helpers from 'lib/helpers/index.js';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1500;
  overflow-y: scroll;
  overflow-x: hidden;
  background: rgba(0, 0, 0, 0.25);
`;

const CenterModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Logo = styled.img`
  height: 50px;
  margin: auto;
  display: flex;
  margin-bottom: 16px;
  margin-top: 16px;
`;

const AuthModalContent = styled.div`
  background: #fff;
  min-height: 350px;
  width: 500px;
  padding: 16px;
  border-radius: 5px;
  max-width: 100%;
  position: relative;
`;

const LoginFormWrapper = ({onClose}) => {
  const [loading, setLoading] = useState(false);
  const [authenticate] = useMutation(AUTHENTICATE);

  const handleLogin = async ({email, password}) => {
    setLoading(true);
    try {
      // sign in with email
      let {data} = await authenticate({
        variables: {
          params: {
            password,
            user: {
              email: email,
            },
          },
        },
      });

      helpers.setLocalStorage(
        constants.authTokenName,
        data.authenticate.tokens.accessToken
      );
      helpers.setLocalStorage(
        constants.refreshTokenName,
        data.authenticate.tokens.refreshToken
      );

      setTimeout(async () => {
        await client.query({
          query: CURRENT_USER,
          fetchPolicy: 'network-only',
        });
        setLoading(false);
        message.success(`Successfully logged in`);
        onClose();
      }, ms('1.5s'));
    } catch (err) {
      let errMessage = err.message.replace('GraphQL', '');
      if (err && err.message.includes('Incorrect password [403]')) {
        errMessage = 'You have entered an invalid username or password';
      }
      setLoading(false);
      console.log([ErrorHelpers.cleanErrorString(errMessage)]);
      return message.error([ErrorHelpers.cleanErrorString(errMessage)]);
    }
  };
  return (
    <>
      <LoginForm onSubmit={handleLogin} loading={loading} />
    </>
  );
};

const SignupFormWrapper = ({onClose}) => {
  const [signupBuyer] = useMutation(SIGNUP_BUYER);
  const [loading, setLoading] = useState(false);

  const handleSignup = async ({
    companyName,
    email,
    password,
    firstName,
    lastName,
  }) => {
    try {
      setLoading(true);
      let res = await signupBuyer({
        variables: {
          companyName,
          email,
          password,
          firstName,
          lastName,
        },
      });

      const {accessToken, refreshToken} = res.data.signupBuyer;

      // set the new login tokens
      helpers.setLocalStorage(constants.authTokenName, accessToken);
      helpers.setLocalStorage(constants.refreshTokenName, refreshToken);

      // // set a timeout so it's not too abrupt, then re-query the user not that we have new tokens (set about)
      setTimeout(async () => {
        await client.query({
          query: CURRENT_USER,
          fetchPolicy: 'network-only',
        });
        setLoading(false);
        notification.success({
          message: 'Success!',
          description:
            "Your account was successfully created. You're now good to go!",
        });
        // window.location.reload();
        onClose();
      }, ms('1.5s'));
    } catch (err) {
      setLoading(false);
      console.log();
    }
  };
  return (
    <>
      <BuyerSignupForm onSubmit={handleSignup} loading={loading} />{' '}
    </>
  );
};

const CloseIcon = styled(CloseOutlined)`
  top: 16px;
  right: 16px;
  position: absolute;
  color: ${(p) => p.theme.colors.neutral6};
  cursor: pointer;
`;

const CloseButton = ({onClick}) => <CloseIcon onClick={onClick} />;

export default function AuthenticationModal() {
  const location = useLocation();
  const history = useHistory();
  const {authenticationModal} = queryString.parse(location.search);

  const onClose = () => {
    const currentParams = queryString.parse(location.search);
    const newParams = {
      ...currentParams,
      authenticationModal: undefined,
    };
    const newString = queryString.stringify(newParams);
    history.push(`${location.pathname}?${newString}`);
  };

  if (authenticationModal && authenticationModal === 'buyerSignup') {
    return (
      <Overlay>
        <CenterModal>
          <AuthModalContent>
            <CloseButton onClick={onClose} />
            <Logo src={logoSVG} alt="Greenside Energy Logo" />
            <SignupFormWrapper onClose={onClose} />
          </AuthModalContent>
        </CenterModal>
      </Overlay>
    );
  }

  if (authenticationModal && authenticationModal === 'login') {
    return (
      <Overlay>
        <CenterModal>
          <AuthModalContent>
            <CloseButton onClick={onClose} />
            <LoginFormWrapper onClose={onClose} />
          </AuthModalContent>
        </CenterModal>
      </Overlay>
    );
  }

  return null;
}
