import React from "react";
import styled from "styled-components";
import { Link, useHistory, useLocation } from "react-router-dom";
// COMPONENTS
import Popover from "components/common/Popover";
import Avatar from "components/common/Avatar";
import WelcomeModal from "./WelcomeModal";
import {
  MenuItemBtn,
  SectionTitle,
  SectionBreak,
} from "components/common/AvatarMenu";
import SetPasswordReminder from "./SetPasswordReminder";
// LIB
import AuthHelpers from "lib/helpers/AuthHelpers";
import ErrorHelpers from "lib/helpers/ErrorHelpers";
import logo from "./logo-light-bg.svg";
import theme from "lib/theme";

// STYLE-COMPONENTS
// ===================================
const Layout = styled.div`
  min-height: 100%;
  width: 100%;
  max-width: 100%;
  background-color: ${(p) => p.theme.colors.neutral10};
`;

const Header = styled.div`
  height: 64px;
  background: ${(p) => p.theme.colors.neutral2};
  .ant-avatar {
    background-color: ${(p) => p.theme.colors.neutral4};
  }
`;

const HeaderInner = styled.div`
  height: 100%;
  width: 1200px;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: auto;
`;

const Content = styled.div`
  height: 100%;
  padding-top: 24px;
  width: 1200px;
  max-width: 100%;
  margin: auto;
`;

const NavLink = styled(Link)`
  font-weight: 600;
  margin-right: 32px;
  color: ${(p) => {
    if (p.active) return p.theme.colors.primary7;
    return p.theme.colors.neutral7;
  }};
  &:hover {
    color: ${(p) => {
      if (p.active) return p.theme.colors.primary7;
      return p.theme.colors.neutral9;
    }};
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 80%;
`;

// EXPORT
// ===================================
export default function SupplierLayout({ children, currentUser }) {
  const location = useLocation();
  const history = useHistory();

  const onLogout = async () => {
    try {
      // run logout function
      await AuthHelpers.signOut();
    } catch (err) {
      return ErrorHelpers.handleError(err);
    }
  };

  return (
    <Layout id="supplier-layout">
      <Header>
        <HeaderInner>
          {" "}
          <Link to={`/supplier/home`}>
            <img src={logo} alt="logo" style={{ height: 40 }} />
          </Link>
          <Nav>
            <NavLink
              to="/supplier/home"
              active={location.pathname.includes("/supplier/home")}
            >
              Find Projects
            </NavLink>
            <NavLink
              to="/supplier/quotes"
              active={location.pathname.includes("/supplier/quotes")}
            >
              My Quotes
            </NavLink>
            <NavLink
              to="/supplier/orders"
              active={location.pathname.includes("/supplier/orders")}
            >
              My Orders
            </NavLink>
          </Nav>
          <div style={{ position: "absolute", right: 0 }}>
            <Popover
              overlayStyle={{ padding: 0, borderRadius: 5 }}
              content={
                <div style={{ width: 200 }}>
                  <SectionTitle>ACCOUNT</SectionTitle>
                  <MenuItemBtn
                    onClick={() =>
                      history.push(`/supplier/settings?tab=account`)
                    }
                  >
                    Account Details
                  </MenuItemBtn>
                  <MenuItemBtn
                    onClick={() => history.push(`/supplier/settings?tab=user`)}
                  >
                    Profile
                  </MenuItemBtn>
                  <MenuItemBtn
                    onClick={() =>
                      history.push(`/supplier/settings?tab=security`)
                    }
                  >
                    Security
                  </MenuItemBtn>
                  <MenuItemBtn
                    onClick={() =>
                      history.push(`/supplier/settings?tab=notifications`)
                    }
                  >
                    Notifications
                  </MenuItemBtn>
                  <SectionBreak />
                  <SectionTitle>Support</SectionTitle>
                  <MenuItemBtn onClick={() => history.push("/support")}>
                    Contact Us
                  </MenuItemBtn>

                  <SectionBreak />
                  <MenuItemBtn
                    onClick={() => onLogout()}
                    style={{ color: theme.colors.red3, marginBottom: 0 }}
                  >
                    Logout
                  </MenuItemBtn>
                </div>
              }
            >
              <Avatar
                style={{
                  cursor: "pointer",
                }}
                size="large"
              >
                {currentUser.firstName && currentUser.firstName.charAt(0)}
                {currentUser.lastName && currentUser.lastName.charAt(0)}
              </Avatar>
            </Popover>
          </div>
        </HeaderInner>
      </Header>
      <Content>{children}</Content>
      {currentUser && currentUser.noPasswordSet && (
        <SetPasswordReminder currentUser={currentUser} />
      )}
      <WelcomeModal />
    </Layout>
  );
}
