import React, {useState} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {validate} from 'email-validator';
import logo from 'lib/media/logo.svg';
// COMPONENST
import Caption from 'components/text/Caption';
import FormItem from 'components/common/FormItem';
import ErrorBlock from 'components/common/ErrorBlock';
import Button from 'components/common/Button';
import TextInput from 'components/inputs/Input';

const Logo = styled.img`
  height: 50px;
  margin: auto;
`;
const TitleContainer = styled.div`
  min-height: 95px;
  text-align: center;
`;

const FormContainer = styled.div`
  width: 500px;
  padding: 16px;
  max-width: 100%;
  margin: auto;
  padding-top: 20px;
  background: #fff;
  padding: 32px 64px;
  border-radius: 5px;
  @media only screen and (max-width: 414px) {
    padding: 20px;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 10px;
`;

export default ({onSubmit, loading}) => {
  const [errors, setErrors] = useState([]);
  const [email, setEmail] = useState(undefined);
  const [password, setPassword] = useState(undefined);

  const handleSubmit = async (e) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    // reset errors
    setErrors([]);
    console.log(email);

    // check that user added an email
    if (!email) {
      return setErrors(['Please provide an email']);
    }
    // check if its a valid email
    if (!validate(email)) {
      return setErrors(['That is not a valid email']);
    }
    // check that they give a password
    if (!password) {
      return setErrors(['Please provide a password']);
    }

    onSubmit({
      email,
      password,
    });
  };

  return (
    <>
      <FormContainer>
        <TitleContainer>
          <Logo src={logo} />
          <Caption style={{margin: '16px auto'}}>Welcome back!</Caption>
        </TitleContainer>
        <form onSubmit={handleSubmit}>
          <FormItem>
            <TextInput
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
            />
          </FormItem>
          <FormItem>
            <TextInput
              value={password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
          </FormItem>
          <ButtonContainer>
            <Button
              disabled={loading}
              loading={loading}
              type="submit"
              onClick={handleSubmit}
            >
              Log in
            </Button>
          </ButtonContainer>
        </form>
        <FormItem>
          <ErrorBlock errors={errors} />
        </FormItem>
        <div>
          <Caption style={{textAlign: 'center', marginTop: 16}}>
            <Link to="/forgot-password">Forgot your password</Link>
          </Caption>
        </div>
      </FormContainer>
    </>
  );
};
