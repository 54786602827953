const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

const theme = {
  boxShadow: `0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)`,
  fontFamily: `'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  // COLORS
  colors: {
    // other
    primary1: '#014D40',
    primary2: '#0C6B58',
    primary3: '#147D64',
    primary4: '#199473',
    primary5: '#27AB83',
    primary6: '#3EBD93',
    primary7: '#65D6AD',
    primary8: '#8EEDC7',
    primary9: '#C6F7E2',
    primary10: '#EFFCF6',
    // Neutrals
    neutral1: '#102A43',
    neutral2: '#243B53',
    neutral3: '#334E68',
    neutral4: '#486581',
    neutral5: '#627D98',
    neutral6: '#829AB1',
    neutral7: '#9FB3C8',
    neutral8: '#BCCCDC',
    neutral9: '#D9E2EC',
    neutral10: '#F0F4F8',
    neutral11: '#F6F8FB',
    // Accent B
    blue1: '#003E6B',
    blue2: '#0A558C',
    blue3: '#0F609B',
    blue4: '#186FAF',
    blue5: '#2680C2',
    blue6: '#4098D7',
    blue7: '#62B0E8',
    blue8: '#84C5F4',
    blue9: '#B6E0FE',
    blue10: '#DCEEFB',
    blue11: '#102a43',
    blue12: '#243b53',
    blue13: '#486581',
    blue14: '#1878BF',
    // Accent B
    supportB1: '#05606E',
    supportB2: '#07818F',
    supportB3: '#099AA4',
    supportB4: '#0FB5BA',
    supportB5: '#1CD4D4',
    supportB6: '#3AE7E1',
    supportB7: '#62F4EB',
    supportB8: '#92FDF2',
    supportB9: '#C1FEF6',
    supportB10: '#E1FCF8',
    supportB11: '#E0FCFF',
    supportB12: '#14919B',
    // Red
    red1: '#610404',
    red2: '#780A0A',
    red3: '#911111',
    red4: '#A61B1B',
    red5: '#BA2525',
    red6: '#D64545',
    red7: '#E66A6A',
    red8: '#F29B9B',
    red9: '#FACDCD',
    red10: '#FFEEEE',
    // Yellow
    yellow1: '#513C06',
    yellow2: '#7C5E10',
    yellow3: '#A27C1A',
    yellow4: '#C99A2E',
    yellow5: '#E9B949',
    yellow6: '#F7D070',
    yellow7: '#F9DA8B',
    yellow8: '#F8E3A3',
    yellow9: '#FCEFC7',
    yellow10: '#FFFAEB',
    // White
    white: '#ffffff',
    white1: '#f0f4f8',
    white2: '#f7fbff',
    // Aqua
    aqua1: '#6897a0',
    // Purple
    purple1: '#8662C7',
    purple2: '#724CB3',
  },
  breakpoints: {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`,
  },
};

export default theme;
