// import portfolio1 from "lib/media/portfolio/portfolio1.svg";
import portfolio2 from "lib/media/portfolio/portfolio2.svg";
import portfolio3 from "lib/media/portfolio/portfolio3.svg";
import portfolio4 from "lib/media/portfolio/portfolio4.svg";
// import portfolio5 from "lib/media/portfolio/portfolio5.svg";
// import portfolio6 from "lib/media/portfolio/portfolio6.svg";
// import portfolio7 from "lib/media/portfolio/portfolio7.svg";
// import portfolio8 from "lib/media/portfolio/portfolio8.svg";
// import portfolio9 from "lib/media/portfolio/portfolio9.svg";
import portfolio10 from "lib/media/portfolio/portfolio10.svg";
import portfolio11 from "lib/media/portfolio/portfolio11.svg";
// import portfolio12 from "lib/media/portfolio/portfolio12.svg";
// import portfolio13 from "lib/media/portfolio/portfolio13.svg";
// import portfolio14 from "lib/media/portfolio/portfolio14.svg";
// import portfolio15 from "lib/media/portfolio/portfolio15.svg";

/* This example requires Tailwind CSS v2.0+ */
export default function Example() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5 align-center">
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src={portfolio2} alt="Tuple" />
          </div>
          <div className="col-span-1 flex justify-center  md:col-span-2 lg:col-span-1">
            <img
              className="h-8"
              src={
                portfolio3 ||
                "https://greenside-public-test.s3.amazonaws.com/Canadian-Solar-logo%201.png"
              }
              alt="Mirage"
            />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img
              className="h-8"
              src={
                portfolio4 ||
                "https://greenside-public-test.s3.amazonaws.com/Enphase-logo%201.png"
              }
              alt="StaticKit"
            />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <img
              className="h-8"
              src={
                portfolio10 ||
                "https://greenside-public-test.s3.amazonaws.com/hanwha%201%20%281%29.png"
              }
              alt="Transistor"
            />
          </div>
          <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
            <img className="h-8" src={portfolio11} alt="Workcation" />
          </div>
        </div>
      </div>
    </div>
  );
}
