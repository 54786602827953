import styled from "styled-components";

export default styled.input`
  background: ${(p) => p.theme.colors.neutral11};
  border: 0px;
  width: 100%;
  height: 50px;
  padding-left: 8px;
  border-radius: 5px;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  border: 2px solid ${(p) => p.theme.colors.neutral10};
  &:hover {
    outline: 0;
    border: 2px solid ${(p) => p.theme.colors.neutral8};
  }
  &:focus {
    outline: 0;
    background: #fff;
    border: 2px solid ${(p) => p.theme.colors.primary7};
  }
  ::placeholder {
    color: ${(p) => p.theme.colors.neutral7};
  }
  &:disabled {
    cursor: not-allowed;
  }
`;
